<div class="d-flex flex-column">
	<div class="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center ls-mb-lg-4 ls-mb-3">
		<h1 class="m">Settings</h1>
		<ls-alert *ngIf="integrationMessage && tabIndex === 2" [alertType]="integrationMessage.type"
			[icon]="integrationMessage.icon" [small]="true">
			<label class="xs">{{ integrationMessage.message }}</label>
		</ls-alert>
		<div>
			<button class="btn btn-primary" (click)="onClick()" [hidden]="hideSave"
				[disabled]="settingForm.invalid || settingForm.pristine">
				Save Changes
			</button>
		</div>
	</div>
	<ls-tabs [(tabIndex)]="tabIndex" [tabNames]="tabNames">
		<form [formGroup]="settingForm">
			<div [hidden]="tabIndex !== 0">
				<ls-company-profile #cpForm *ngIf="!isReadOnly" [companyProfile]="companyProfile">
				</ls-company-profile>
				<ls-company-profile-readonly #cpRoForm *ngIf="isReadOnly" [companyProfile]="companyProfile">
				</ls-company-profile-readonly>
			</div>
			<button *ngif="!isOnboardingV103Enabled" type="button" (click)="logOut()"
				class="btn btn-icon btn-secondary btn-lg">
				<span class="material-icons md-18 ls-mr-2">logout</span>Log Out
			</button>
			<ls-user-profile [hidden]="tabIndex !== 1" [userInfo]="userInfo" #userProfile>
			</ls-user-profile>
			<ls-integrations [hidden]="tabIndex !== 2" [companyProfile]="companyProfile"
				(showMessage)="showIntegrationMessage($event)"></ls-integrations>
		</form>
	</ls-tabs>
</div>
<ng-template #successToast>
	<ls-alert [small]="true">Your changes have been successfully saved.</ls-alert>
</ng-template>