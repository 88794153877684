import { IValidation } from "./Interfaces";

export class EmailValidation implements IValidation {
	type = "Email";
	email?: string;

	constructor(email?: string) {
		this.email = email;
	}
}
