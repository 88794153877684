import { createAction, props } from "@ngrx/store";
import { ActionType, LsAction } from "src/app/Core/LsAction";
import { EmailValidation, BankAccountValidation, LsHttpErrorResponse, PhoneValidation } from "src/app/Models";

export abstract class ValidationActions extends LsAction {
	/* eslint-disable */
  static PHONE_VALIDATION: Readonly<string> = 'PHONE_VALIDATION';
  static EMAIL_VALIDATION: Readonly<string> = 'EMAIL_VALIDATION';
  static BANK_ACC_VALIDATION: Readonly<string> = 'BANK_ACC_VALIDATION';
  static VALIDATION_RESULT: Readonly<string> = 'VALIDATION_RESULT';
  /* eslint-enable */

	static validatePhone = createAction(
		ValidationActions.getActionName(ValidationActions.PHONE_VALIDATION, ActionType.VALIDATE),
		props<{ id: string; phone: PhoneValidation }>()
	);

	static validateEmail = createAction(
		ValidationActions.getActionName(ValidationActions.EMAIL_VALIDATION, ActionType.VALIDATE),
		props<{ id: string; email: EmailValidation }>()
	);

	static validationFailure = createAction(
		ValidationActions.getActionName(ValidationActions.VALIDATION_RESULT, ActionType.VALIDATE),
		props<{ id: string; fieldValue?: string; validationType: string; result?: LsHttpErrorResponse }>()
	);

	static validateBankAcc = createAction(
		ValidationActions.getActionName(ValidationActions.BANK_ACC_VALIDATION, ActionType.VALIDATE),
		props<{ bankAccount: BankAccountValidation }>()
	);
}
