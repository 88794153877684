import { Store } from "@ngrx/store";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../Services";
import { Component, OnInit } from "@angular/core";
import { CodatActions, CodatSelectors, CompanyProfileSelectors } from "src/app/AppStateManagement";
import { filter, map, takeUntil } from "rxjs";
import { DisplayedBankAccount } from "src/app/Models/DisplayedBankAccount";
import { BankAccount, CompanyProfile, DisplayConfig, Layout } from "../../../../../Models";
import { TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-confirm-bank-account",
	templateUrl: "./confirm-bank-account.component.html",
	styleUrls: ["./confirm-bank-account.component.scss"]
})
export class ConfirmBankAccountComponent extends RouteStepDataBaseComponent implements OnInit {
	public bankName?: string;
	public accountNumber?: string;
	public alias?: string;
	public confirmIndex = 0;
	public changeIndex = 1;
	public isManualBankAccount = false;
	BANK_NAME_TOKEN = "!BANK_NAME!";
	ACCOUNT_NUMBER_TOKEN = "!ACCOUNT_NUMBER_TOKEN!";
	ALIAS_TOKEN = "!ALIAS!";

	constructor(
		public store: Store,
		public routeStepDataService: RouteStepDataService,
		private _codatSelectors: CodatSelectors,
		private _companyProfileSelectors: CompanyProfileSelectors
	) {
		super(routeStepDataService);
		this.setDefaultLayout(this.DefaultLayout);
	}

	public ngOnInit(): void {
		this.store
			.select(this._companyProfileSelectors.selectCompanyProfile)
			.pipe(
				takeUntil(this.componentTeardown$),
				filter((cp) => !!cp),
				map((companyProfile) => {
					this.setRoutingIndexes(companyProfile!.bankAccount);
					if (companyProfile!.isBankingPlatformConnected) {
						this.store.dispatch(CodatActions.getBankAccounts({ companyId: companyProfile!.companyId! }));
						this.store
							.select(this._codatSelectors.selectBankAccounts)
							.pipe(
								takeUntil(this.componentTeardown$),
								filter((ba) => !!ba),
								map((ba) => {
									this.setBankAccounts(companyProfile!, ba!);
								})
							)
							.subscribe();
					} else {
						const ba = companyProfile!.bankAccount;
						if (ba != null) {
							this.setFormBankData(
								new DisplayedBankAccount(ba.id?.toString() ?? "", "", ba.accountNumber ?? "", ba.bankName, ba.alias)
							);
						}
					}
				})
			)
			.subscribe();
	}

	public setBankAccounts(companyProfile: CompanyProfile, bankAccounts: DisplayedBankAccount[]) {
		bankAccounts?.map((ba: DisplayedBankAccount) => {
			if (ba.name === companyProfile!.bankAccount?.alias && companyProfile?.isBankingPlatformConnected) {
				this.setFormBankData(ba);
			}
		});
	}

	public setFormBankData(bankAccount: DisplayedBankAccount) {
		this.bankName = bankAccount.institution;
		this.accountNumber = bankAccount.accountNumber;
		this.alias = bankAccount.alias;
		this.replacementTokens.set(this.BANK_NAME_TOKEN, this.bankName);
		this.replacementTokens.set(this.ALIAS_TOKEN, this.alias);
		this.replacementTokens.set(this.ACCOUNT_NUMBER_TOKEN, this.accountNumber);
	}

	public setRoutingIndexes(bankAccount?: BankAccount): void {
		if (!bankAccount?.codatBankAccountId) {
			this.confirmIndex = 2;
			this.changeIndex = 3;
			this.isManualBankAccount = true;
		}
	}

	public onClick(index: number): void {
		let state = {};
		if (index === this.changeIndex && this.isManualBankAccount) {
			state = {
				bypassCodatGuard: true
			};
		}
		this.nav(index, undefined, undefined, state);
	}
	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig("Select your account", TextStyle.HEADLINE_LARGE)],
					[
						"p1",
						new DisplayConfig(
							`You're already receiving payments to ${this.ALIAS_TOKEN} account in ${this.BANK_NAME_TOKEN}.`,
							TextStyle.BODY_EXTRA_EXTRA_LARGE_BOLD
						)
					],
					[
						"p2",
						new DisplayConfig(
							"Please confirm if you want to receive payments into this account again or if you'd like to change your account.",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		],
		[
			this.BUTTONS,
			new Layout(
				new Map<string, DisplayConfig>([
					["confirm", new DisplayConfig("Confirm", TextStyle.BUTTON_LARGE)],
					["change", new DisplayConfig("Change account", TextStyle.BUTTON_LARGE)]
				])
			)
		]
	]);
}
