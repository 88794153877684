import { Component, Input } from "@angular/core";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { DisplayConfig } from "src/app/Models";
import { TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-info-dialog",
	templateUrl: "./info-dialog.component.html",
	styleUrls: ["./info-dialog.component.scss"]
})
export class InfoDialogComponent {
	@Input() header?: string;
	@Input() content?: string;
	constructor(public modalRef: MdbModalRef<any>) {}
	get headerConfig() {
		return new DisplayConfig(this.header ?? "", TextStyle.TITLE_LARGE);
	}
	get contentConfig() {
		return new DisplayConfig(this.content ?? "", TextStyle.BODY_LARGE);
	}
}
