import { IValidation } from "./Interfaces";
import { BankAccount } from "./BankAccount";

export class BankAccountValidation implements IValidation {
	type = "BankAccount";
	bankAccount?: BankAccount;

	constructor(bankAccount?: BankAccount) {
		this.bankAccount = bankAccount;
	}
}
