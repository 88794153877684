import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, switchMap } from "rxjs";
import { ExportService, SalesforceSupportService } from "src/services";
import { HttpErrorService } from "src/services/HTTP";
import { SalesforceSupportActions } from "./salesforce-support-actions";
import { HttpErrorResponse } from "@angular/common/http";
import { LsHttpErrorResponse } from "src/app/Models";

@Injectable()
export class SalesforceSupportEffects {
	constructor(
		private actions$: Actions,
		private salesforceSupportService: SalesforceSupportService,
		private httpErrorService: HttpErrorService,
		private exportService: ExportService
	) {}

	getCase$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SalesforceSupportActions.getCase),
			mergeMap((act) =>
				this.salesforceSupportService
					.getCase(act.caseId)
					.pipe(map((c) => SalesforceSupportActions.setCase({ supportCase: c })))
			)
		)
	);

	getCases$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SalesforceSupportActions.getCases),
			mergeMap(() =>
				this.salesforceSupportService.getCases().pipe(map((cs) => SalesforceSupportActions.setCases({ cases: cs })))
			)
		)
	);

	updateCase$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SalesforceSupportActions.updateCase),
			mergeMap((act) =>
				this.salesforceSupportService.updateCase(act.supportCase).pipe(
					switchMap((c) => [
						SalesforceSupportActions.setCase({ supportCase: c }),
						SalesforceSupportActions.caseSaveSuccessful(),
						SalesforceSupportActions.getCases()
					]),
					catchError((err) => {
						console.error(err);
						return this.httpErrorService
							.parseError(err)
							.pipe(map((resp) => SalesforceSupportActions.caseSaveUnsuccessful({ errors: resp })));
					})
				)
			)
		)
	);

	sendEmailMessage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SalesforceSupportActions.sendEmailMessage),
			mergeMap((act) =>
				this.salesforceSupportService.sendEmailMessage(act.emailMessage, act.userId, act.caseId, act.files).pipe(
					map(() => SalesforceSupportActions.emailMessageSendSuccessful()),
					catchError((error: HttpErrorResponse) => {
						const errResp = new LsHttpErrorResponse(
							error.error.status,
							error.error.title,
							error.error.title,
							error.error.type,
							error.error.errors
						);
						return [SalesforceSupportActions.emailMessageSendUnsuccessful({ error: errResp })];
					})
				)
			)
		)
	);

	downloadAttachment$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(SalesforceSupportActions.downloadAttachment),
				mergeMap((act) =>
					this.salesforceSupportService.downloadAttachment(act.blobPath, act.fileName, act.userId, act.caseId).pipe(
						map((response) => {
							this.exportService.downloadFile(response);
						})
					)
				)
			),
		{ dispatch: false }
	);
}
