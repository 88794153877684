<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()" [isNextButtonDisabled]="customerContactForm.invalid">
	<div *ngIf="display(HEADER)">
		<ls-text [displayConfig]="config(HEADER, 'hs1')" extraClasses="m no-text transform ls-mb-3"></ls-text>
	</div>
	<div *ngIf="display(BODY)">
		<ls-text [displayConfig]="config(BODY, 'p1')" extraClasses="ls-mb-8"></ls-text>
		<form [formGroup]="customerContactForm">
			<div class="customer-contact d-flex flex-column">

				<mdb-form-control class="legal-name ls-mb-8">
					<input mdbInput [formControlName]="LEGAL_NAME" mdbValidate type="text" id="legalName"
						   class="form-control form-control-lg" />
					<label mdbLabel class="form-label" for="legalName">{{ config(BODY, "q1-l1")?.text }}</label>
				</mdb-form-control>

				<div class="d-flex flex-column">
					<ls-text [displayConfig]="config(BODY, 'q2-l1')" extraClasses="ls-mb-3"></ls-text>
					<ls-address-form-control [formControlName]="BILLING"></ls-address-form-control>
				</div>
			</div>
		</form>
	</div>
		
</ls-questionnaire>