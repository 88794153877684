import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../Services";
import { DisplayConfig, Layout } from "../../../../../Models";
import { TextStyle } from "../../../../../Models/Enums";

@Component({
	selector: "ls-manual",
	templateUrl: "./manual.component.html",
	styleUrls: ["./manual.component.scss"]
})
export class ManualComponent extends RouteStepDataBaseComponent {
	constructor(
		public store: Store,
		public router: Router,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
		this.setDefaultLayout(this.DefaultLayout);
	}

	navToAccounting() {
		this.nav(1);
	}

	continue() {
		this.nav(0, new Map<string, string | number>([["relationshipCompanyId", "new"]]));
	}

	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig("Now, let’s set up your customers", TextStyle.HEADLINE_LARGE)]
				])
			)
		],
		[
			this.BODY,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"p1",
						new DisplayConfig(
							"Set up which customer(s) you want advanced payments from. You must connect at least one customer to complete your application, and you can always add more later.",
							TextStyle.BODY_LARGE
						)
					],
					[
						"p2",
						new DisplayConfig(
							"To make this process quicker and easier, you can connect your accounting platform. We’ll gather most of your customer information directly from there to save you time searching for and calculating that info.",
							TextStyle.BODY_LARGE
						)
					],
					[
						"p3",
						new DisplayConfig(
							"Raistone will not contact your customers without your prior approval.",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		],
		[
			this.BUTTONS,
			new Layout(
				new Map<string, DisplayConfig>([
					["b1", new DisplayConfig("Add a customer", TextStyle.BUTTON_LARGE)],
					["b2", new DisplayConfig("Connect accounting", TextStyle.BUTTON_LARGE)]
				])
			)
		]
	]);
}
