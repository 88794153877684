import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { LimestoneElementToggleComponent } from "./toggle/limestone-element-toggle.component";
import { CommonModule } from "@angular/common";
import { TooltipDirective } from "./tooltip/tooltip.directive";
import { LimestoneElementTooltipComponent } from "./tooltip/limestone-element-tooltip.component";
import { LimestoneElementUploadWidgetComponent } from "./upload-widget/limestone-element-upload-widget.component";
import { DragDirective } from "./upload-widget/dragDrop.directive";
import { FileUploadService } from "./upload-widget/file-upload.service";
import { LimestoneElementStepperComponent } from "./stepper/limestone-element-stepper.component";
import { LimestoneElementSingleUseCodeComponent } from "./single-use-code/limestone-element-single-use-code.component";
import { Guid } from "../Utils/Guid";
import { LimestoneElementAccordionComponent } from "./accordion/accordion.component";
import { LimestoneElementRangeComponent } from "./range/range.component";
import { LimestoneElementAlertComponent } from "./alert/limestone-element-alert.component";
import { TextComponent } from "src/app/Core";
import { RadioButtonsComponent } from "./radio-buttons/radio-buttons.component";
import { InfoDialogComponent } from "./tooltip/info-dialog.component";
import { InfoDialogIconComponent } from "./tooltip/info-dialog-icon.component";
import { InfoDialogService } from "src/services";
@NgModule({
	declarations: [
		LimestoneElementToggleComponent,
		TooltipDirective,
		LimestoneElementTooltipComponent,
		LimestoneElementUploadWidgetComponent,
		DragDirective,
		LimestoneElementStepperComponent,
		LimestoneElementSingleUseCodeComponent,
		LimestoneElementAccordionComponent,
		LimestoneElementRangeComponent,
		LimestoneElementAlertComponent,
		RadioButtonsComponent,
		TextComponent,
		InfoDialogComponent,
		InfoDialogIconComponent
	],
	imports: [CommonModule, ReactiveFormsModule],
	exports: [
		LimestoneElementToggleComponent,
		TooltipDirective,
		LimestoneElementTooltipComponent,
		LimestoneElementUploadWidgetComponent,
		DragDirective,
		LimestoneElementStepperComponent,
		LimestoneElementSingleUseCodeComponent,
		LimestoneElementAccordionComponent,
		LimestoneElementRangeComponent,
		LimestoneElementAlertComponent,
		RadioButtonsComponent,
		TextComponent,
		InfoDialogComponent,
		InfoDialogIconComponent
	],
	providers: [FileUploadService, Guid, InfoDialogService]
})
export class LimestoneElementsModule {}
