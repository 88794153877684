import { Address } from "./";
import { GenericLookup } from "@limestone/ls-shared-modules";

export class CompanyAddress {
	companyId?: number;
	addressType?: GenericLookup<string>;
	isActive?: boolean;
	address?: Address;

	constructor(companyId?: number, addressType?: GenericLookup<string>, isActive?: boolean, address?: Address) {
		this.companyId = companyId;
		this.addressType = addressType;
		this.isActive = isActive;
		this.address = address;
	}
}
