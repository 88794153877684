import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class LocalStorageService {
	private readonly COMPANY_ID: string = "companyId";
	private readonly EMAIL_ADDRESS: string = "emailAddress";
	private readonly B2C_LOGIN_STATE: string = "b2c_isLogin";

	setCompanyId(companyId: number): void {
		localStorage.setItem(this.COMPANY_ID, `${companyId}`);
	}

	setEmailAddress(emailAddress: string): void {
		localStorage.setItem(this.EMAIL_ADDRESS, emailAddress);
	}

	getCompanyId(): number | undefined {
		const compStr = localStorage.getItem(this.COMPANY_ID);
		if (compStr) {
			return parseInt(compStr);
		} else {
			return undefined;
		}
	}

	getEmailAddress(): string | undefined {
		return localStorage.getItem(this.EMAIL_ADDRESS) ?? undefined;
	}

	setB2CSignupState(isLogin = false): void {
		localStorage.setItem(this.B2C_LOGIN_STATE, isLogin.toString());
	}

	getB2CSignupState(): boolean {
		const loginState = localStorage.getItem(this.B2C_LOGIN_STATE);
		if (typeof loginState === "string") {
			return JSON.parse(loginState);
		} else {
			return true;
		}
	}
}
