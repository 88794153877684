/* eslint-disable @typescript-eslint/naming-convention */
export enum ActionType {
	GET = "GET",
	SET = "SET",
	SAVE = "SAVE",
	DELETE = "DELETE",
	COMPLETE = "COMPLETE",
	ERROR = "ERROR",
	CLEAR = "CLEAR",
	UPDATE = "UPDATE",
	INSERT = "INSERT",
	TRIGGER = "TRIGGER",
	VALIDATE = "VALIDATE",
	DOWNLOAD = "DOWNLOAD",
	UPLOAD = "UPLOAD",
	UPLOAD_TO_SALESFORCE = "UPLOAD_TO_SALESFORCE"
}

export abstract class LsAction {
	static getActionName = (base: string, type: ActionType) => {
		return `${base}/${type}`;
	};
}
