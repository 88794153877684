import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, Observable } from "rxjs";
import { CompanyProfile } from "src/app/Models";
import { CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { CompanyDetailsBaseComponent } from "../../company-details-base.component";
import { RouteStepDataService } from "../../../Services";
import { SharedText } from "../../customer-setup/shared-text";

@Component({
	selector: "ls-lien-information",
	templateUrl: "./lien-information.component.html",
	styleUrls: ["./lien-information.component.scss"]
})
export class LienInformationComponent extends CompanyDetailsBaseComponent {
	constructor(
		public store: Store<any>,
		public router: Router,
		public genericSelectors: GenericSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(store, router, routeStepDataService);
	}

	YESNO = "YESNO";
	public yesNo?: GenericLookup<string>[];
	get SharedText() {
		return SharedText;
	}
	onClick() {
		const updatedCompanyProfile = copy(this.companyProfile!);
		updatedCompanyProfile!.lienCollaborationYesNoId = this.form.get(this.YESNO)?.value;
		this.emitSave(updatedCompanyProfile);
		const path = this.companyProfile?.isCustomersInProgress ? 0 : 1;
		this.nav(path);
	}
	init(): Observable<CompanyProfile | undefined> {
		return combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.YesNo))
		]).pipe(
			filter(([cp, yn]) => !!cp && !!yn && yn.length > 0),
			map(([cp, yn]) => {
				this.companyProfile = cp;
				this.yesNo = yn!.reverse();
				this.form = new FormGroup({
					YESNO: new FormControl(this.companyProfile?.lienCollaborationYesNoId, Validators.required)
				});
				return cp;
			})
		);
	}
}
