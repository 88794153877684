import { LsAction } from "../../../../Core";
import { createAction, props } from "@ngrx/store";
import { ActionType } from "../../../../Core/LsAction";
import { DocusignDocument } from "../../../../Models/Enums";

export abstract class DocusignActions extends LsAction {
	/* eslint-disable */
	static DOCUSIGN: Readonly<string> = 'DOCUSIGN';
	/* eslint-enable */

	static getDocumentUrl = createAction(
		DocusignActions.getActionName(DocusignActions.DOCUSIGN, ActionType.GET),
		props<{ document: DocusignDocument; redirectUrl: string }>()
	);
}
