<ls-questionnaire *ngIf="!displayConfig || (displayConfig && displayConfig.display) " [breadcrumbs]="breadcrumbs"
  (nextButtonClicked)="buttonClicked.emit()">
  <h2 *ngIf="header && !displayConfig" class="l ls-mb-6">{{header}}</h2>
  <ls-text *ngIf="displayConfig" [displayConfig]="displayConfig" extraClasses="ls-mb-6"></ls-text>
  <div class="ls-mb-6">
    <ng-content></ng-content>
  </div>
  <div class="d-flex flex-row align-items-center">
    <p class="label l-bold ls-mb-0 toggleBoolean-label">{{toggleLabel}}</p>
    <div class="d-flex flex-row align-items-center">
      <ls-toggle [(toggled)]="enabled" (toggledChange)="enabledChange.emit($event)"></ls-toggle>
      <p class="m-bold ls-mb-0 ls-ml-3">{{toggledState}}</p>
    </div>
  </div>
</ls-questionnaire>