import {
	Component,
	Input,
	OnInit,
	Output,
	EventEmitter,
	OnDestroy,
	ChangeDetectorRef,
	ElementRef
} from "@angular/core";
import { CdkStepper } from "@angular/cdk/stepper";
import { filter, map, Subject, takeUntil } from "rxjs";
import { ChildStepType } from "../../app/AppStateManagement/Stepper/stepper-reducer";
import { FeatureFlags, FeatureFlagService } from "@limestone/ls-shared-modules";
import { Directionality } from "@angular/cdk/bidi";

@Component({
	selector: "ls-stepper",
	templateUrl: "./limestone-element-stepper.component.html",
	styleUrls: ["./limestone-element-stepper.component.scss"],
	providers: [{ provide: CdkStepper, useExisting: LimestoneElementStepperComponent }]
})
export class LimestoneElementStepperComponent extends CdkStepper implements OnInit, OnDestroy {
	public isV1_0_3_FlagEnabled?: boolean = false;
	private componentTeardown$ = new Subject();
	@Input() lsSteps: LsStep[] = [];
	private _activeIndex = 0;
	@Input() set activeStep(index: number) {
		this.selectStepByIndex(index);
		this._activeIndex = index;
	}
	get activeStep() {
		return this._activeIndex;
	}
	public expanded = false;
	@Input() displaySettingsButton = true;
	@Input() displayNavBackButton = false;
	@Input() navBackName = "";
	@Input() showActiveStep = true;
	@Output() navBackClicked: EventEmitter<any> = new EventEmitter<any>();
	@Output() settingsClicked: EventEmitter<any> = new EventEmitter<any>();
	@Output() stepClicked: EventEmitter<number> = new EventEmitter<number>();
	@Output() expandedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() logoutClicked: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		_dir: Directionality,
		_changeDetectorRef: ChangeDetectorRef,
		_elementRef: ElementRef,
		private featureFlagService: FeatureFlagService
	) {
		super(_dir, _changeDetectorRef, _elementRef);
	}

	ngOnInit(): void {
		this.featureFlagService
			.isEnabled(FeatureFlags.Onboarding_V1_0_3)
			.pipe(
				filter((isEnabled) => !!isEnabled),
				takeUntil(this.componentTeardown$),
				map((isEnabled) => {
					this.isV1_0_3_FlagEnabled = isEnabled;
				})
			)
			.subscribe();
		this.expandedChanged.emit(this.expanded);
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	getIcon(index: number): string | number {
		let label = `${index + 1}`;
		if (this.lsSteps[index].percentComplete === 100) {
			label = this.lsSteps[index].iconAfterComplete ?? label;
		} else {
			label = this.lsSteps[index].icon ?? label;
		}
		return label;
	}

	getPercent(index: number): string {
		return this.lsSteps[index].percentComplete + "%";
	}

	handleSettingsClicked(): void {
		this.settingsClicked.emit();
	}

	hasIcon(index: number): boolean {
		return (
			!!this.lsSteps[index].icon ||
			(this.lsSteps[index].percentComplete === 100 && !!this.lsSteps[index].iconAfterComplete)
		);
	}

	isCompleted(index: number): boolean {
		return this.lsSteps[index].percentComplete === 100;
	}

	isInProgress(index: number): boolean {
		return this.lsSteps[index].percentComplete > 0;
	}

	navBack(): void {
		this.navBackClicked.emit();
	}

	selectStepByIndex(index: number): void {
		this.selectedIndex = index;
	}

	emitStepClick(index: number): void {
		this.stepClicked.emit(index);
		if (this.expanded) {
			this.setExpandedState();
		}
	}

	getStepLayout(): string {
		return this.expanded ? "column" : "row";
	}

	getStepLayoutAlign(): string {
		return this.expanded ? "start" : "space-between center";
	}

	setExpandedState() {
		this.expanded = !this.expanded;
		this.expandedChanged.emit(this.expanded);
	}
}

export class LsStep {
	/**
	 * The text label of the step.
	 */
	label = "";
	/**
	 * Percent as whole number.
	 */
	percentComplete = 0;
	/**
	 * Override the default step number with an icon.
	 */
	icon?: string;
	/**
	 * After a step has completed display a new icon.
	 */
	iconAfterComplete?: string;
	/**
	 * Total number of substeps.
	 */
	substeps = 1;
	/**
	 * Total number of substeps.
	 */
	completedSubsteps?: number = 0;
	/**
	 * The text label of the step.
	 */
	route = "";
	/**
	 * The child steps of the step.
	 */
	children?: LsStep[] = [];
	/**
	 * The RouteStepData.substep value of the specific child step
	 * to begin displaying child steps in the stepper
	 */
	childDisplayStep?: number;
	/**
	 * The child step type
	 */
	childStepType?: ChildStepType;
	/**
	 * Flag indicating child steps is always 'completed'
	 */
	childStepAlwaysComplete?: boolean = false;
	/*
	 * Toggle display for settings icon. Defaulted to true.
	 */
	displaySettings = true;
}
