<ng-container *ngIf="(success$ | async); then connectionSuccess; else connectionFailed"></ng-container>
<ng-template #connectionSuccess>
	<ls-text extraClasses="ls-mb-4" *ngIf="display(HEADER)" [displayConfig]="config(HEADER, 'hl1')"></ls-text>
	<ls-text extraClasses="ls-mb-4 ls-mb-xxl-2" *ngIf="display(BODY)" [displayConfig]="config(BODY, 't1')"></ls-text>
	<ls-text extraClasses="ls-mb-6 ls-mb-xxl-11" *ngIf="display(BODY) && !scfFlow" [displayConfig]="config(BODY, 'p1')">
		<ls-tooltip [position]="'right'">
			<span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
			<div content>
				<ls-text extraClasses="text-wrap" [displayConfig]="config(HEADER,'tt-l1')"></ls-text>
				<ls-text extraClasses="ls-mb-0 ls-mt-2 text-wrap" [displayConfig]="config(HEADER,'tt-p1')"></ls-text>
			</div>
		</ls-tooltip>
	</ls-text>

  <div *ngIf="scfFlow" class="ls-mb-6 ls-mb-xxl-11">
	  <ls-text extraClasses="ls-mb-6 ls-mb-xxl-11" [displayConfig]="config(SCF_CONTENT, 'p1')"></ls-text>

    <div *ngIf="(bankAccounts?.length ?? 0) === 0">
      <div *ngIf="showSpinner" class="text-center ls-mt-6">
        <div class="spinner-border ls-spinner" role="status">
          <span class="visually-hidden">
			<ls-text extraClasses="visually-hidden" [displayConfig]="config(SCF_CONTENT, 'p2')"></ls-text>
		  </span>
		</div>
	  	<ls-text extraClasses="ls-spinner-text" [displayConfig]="config(SCF_CONTENT, 'p2')"></ls-text>
      </div>
      <div *ngIf="!showSpinner" class="form-text validation-error">
		  <ls-text extraClasses="form-text validation-error" [displayConfig]="config(SCF_CONTENT, 'p3')"></ls-text>
      </div>
    </div>
    <div class="grid">
      <div *ngFor="let ba of bankAccounts" class="g-col-xl-4 g-col-lg-6 g-col-12">
        <button type="button" class="w-100 btn act-btn" (click)="selectBankAccount(ba.id!)"
          [ngClass]="isSelected(ba?.id) ? 'selected' : 'btn-outline-tertiary'">
          <div class="d-flex flex-row align-items-center justify-content-left">
            <span class="material-icons md-18 ls-icon-primary-no-background">account_balance</span>
            <span class="label l-bold ls-ml-2 justify-content-left">{{ba.name}} - {{ba.accountNumber}}</span>
          </div>
        </button>
      </div>
    </div>
    <ng-container>
      <form class="w-50 ls-mt-6" [formGroup]="form" [hidden]="!isSelectedChaseAccount">
        <mdb-form-control>
          <input mdbInput [formControlName]="ACCOUNT_NUMBER" mdbValidate type="text" [id]="ACCOUNT_NUMBER"
            class="form-control form-control-lg" (keydown)="onKeyboardEvent($event)"
            (paste)="onClipboardEvent($event)" />
			<label mdbLabel class="form-label" [for]="ACCOUNT_NUMBER">
				<ls-text [displayConfig]="config(SCF_CONTENT, 'l1')"></ls-text>
			</label>
			<mdb-error *ngIf="controlHasError(ACCOUNT_NUMBER)">{{getControlError()}}</mdb-error>
        </mdb-form-control>
      </form>
    </ng-container>
  </div>
  <div class="d-flex flex-md-row flex-column justify-content-between">
	<button type="button" (click)="connectBank()" [disabled]="isDisabled()" class="btn btn-primary btn-lg ls-mb-4 ls-mb-md-0">
		<ls-text [displayConfig]="config(BUTTONS,'b1')"></ls-text>
	</button>
	<button *ngIf="!scfFlow" type="button" (click)="continue()" class="btn btn-outline-tertiary btn-lg">
		<ls-text [displayConfig]="config(BUTTONS,'b2')"></ls-text>
	</button>
  </div>
</ng-template>
<ng-template #connectionFailed>
	<ls-text extraClasses="ls-mb-4 ls-mb-xxl-8" *ngIf="display(FAILED_CONNECTION)" [displayConfig]="config(FAILED_CONNECTION, 'hl1')"></ls-text>
	<ls-text extraClasses="ls-mb-6 ls-mb-xxl-11" *ngIf="display(FAILED_CONNECTION)" [displayConfig]="config(FAILED_CONNECTION, 'p1')"></ls-text>
	
  <div class="d-flex flex-sm-row flex-column justify-content-between">
    <button type="button" (click)="connectBank()" class="btn btn-primary btn-lg ls-mb-2 ls-mb-sm-0">
		<ls-text [displayConfig]="config(BUTTONS,'b3')"></ls-text>
	</button>
    <button type="button" (click)="continue()" class="btn btn-primary-borderless btn-lg ls-p-0">
		<ls-text [displayConfig]="config(BUTTONS,'b4')"></ls-text>
	</button>
  </div>
</ng-template>