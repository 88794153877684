export class DisplayedBankAccount {
	id: string;
	name: string;
	accountNumber: string;
	institution: string;
	alias: string;

	constructor(id: string, name: string, accountNumber: string, institution: string, alias: string) {
		this.id = id;
		this.name = name;
		this.accountNumber = accountNumber;
		this.institution = institution;
		this.alias = alias;
	}
}
