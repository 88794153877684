<h1 class="m ls-mb-11">Marketplace</h1>
<div class="marketplace-card ls-p-5 rounded-6" [class.ls-mb-4]="isFirst" *ngFor="let card of cards; let isFirst = first;">
    <div class="card-body">
      <p class="title m">{{ card.title }}</p>
      <p class="card-text m" [class.ls-mb-3]="isFirst" *ngFor="let paragraph of card.paragraphs; let isFirst = first;">{{ paragraph }}</p>
      
      <div class="d-flex flex-row justify-content-between align-items-center">
        <p class="l-bold see-more-details" (click)="openModal(card.details)">See more details</p>
		  <button type="button" class="btn btn-primary" [disabled]="card.id === 'SCF_V1' && hasScfLead"
				  (click)="applyNow(card)"> {{buttonLabel(card)}}
		  </button>
	  </div>
    </div>
</div>
<ng-template #successToast>
	<ls-alert [small]="true">We've contacted our Raistone Sales team on your behalf and let them know that you want to apply for 
    a Supply Chain Financing program. Someone from our Sales team will contact you for next steps.</ls-alert>
</ng-template>
