import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, of, zip } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class HttpErrorService {
	public parseError(err: HttpErrorResponse, keyMapping?: string[]): Observable<string[]> {
		let resp$: Observable<HttpErrorResponse> = of();
		if (err.status === 400) {
			if (err.error instanceof Blob) {
				resp$ = this.blobErrorResponse$(err);
			} else {
				resp$ = of(err);
			}
		} else if (err.status === 500) {
			return of(["Service unavailable."]);
		}
		return resp$.pipe(map((resp) => this.parse(resp, keyMapping)));
	}

	private blobErrorResponse$(err: HttpErrorResponse): Observable<HttpErrorResponse> {
		return zip((err.error as Blob).text(), of(err)).pipe(
			map(([errText, resp]) => {
				const error = JSON.parse(errText);
				return new HttpErrorResponse({
					error,
					headers: resp.headers,
					status: resp.status,
					statusText: resp.statusText,
					url: resp.url ?? ""
				});
			})
		);
	}

	private parse(err: HttpErrorResponse, keyMapping?: string[]): string[] {
		const errors = [];
		if (err.status === 400) {
			const validationErrors = err.error.errors;
			const errorMap = new Map<number | string, string>();
			Object.keys(validationErrors).forEach((error) => {
				if (error.startsWith("[")) {
					const indxStr = error.split("]");
					const indx = parseInt(indxStr[0].split("[")[1], 10);
					const msgArr: string[] = validationErrors[error];
					const msg = msgArr.join(" ");
					if (errorMap.has(indx)) {
						let errorMsg = errorMap.get(indx);
						errorMsg += msg;
						errorMap.set(indx, errorMsg ?? "");
					} else {
						errorMap.set(indx, msg);
					}
				} else {
					errorMap.set(error, validationErrors[error]);
				}
			});
			errorMap.forEach((errMsg, key) => {
				if (errors.length < key) {
					for (let i = errors.length - 1; i < key; i++) {
						errors.push(null);
					}
				}
				if (typeof key === "string") {
					errors.push(errMsg);
				} else {
					if (keyMapping != null) {
						const mapping = `${keyMapping[key]}:` ?? "";
						errors[key] = `${mapping} ${errMsg}`;
					}
				}
			});
		} else if (err.status === 500) {
			errors.push("Service unavailable.");
		}
		return errors;
	}
}
