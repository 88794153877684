import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DocusignDocument } from "../../../Models/Enums";
import { HttpService } from "src/services/HTTP";
import { map } from "rxjs/operators";

@Injectable()
export class DocusignService extends HttpService {
	constructor(public http: HttpClient) {
		super(http);
		this.path = "/api/docusign";
	}

	getDocusignUrl(document: DocusignDocument, redirectUrl: string): Observable<string> {
		const url = `${this.path}/${document}`;
		return this.http.post(url, { url: redirectUrl }, { responseType: "text" }).pipe(map((resp) => resp));
	}
}
