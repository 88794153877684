import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { CompanyDocument } from "src/app/Models";
import { CompanyDocumentType } from "../../../Models/Enums";
import { FileHandle } from "../../../../Elements/upload-widget/FileHandle";
import { HttpService } from "src/services/HTTP";

@Injectable()
export class CompanyDocumentService extends HttpService {
	constructor(public http: HttpClient) {
		super(http);
		this.path = "/api/companyDocument";
	}

	getCompanyDocuments(id: number, documentType: CompanyDocumentType): Observable<CompanyDocument[]> {
		const url = `${this.path}/company/${id}`;
		let params = new HttpParams();
		params = params.set("documentType", documentType.toString());
		return this.http
			.get<CompanyDocument[]>(url, { params })
			.pipe(
				map((resp: CompanyDocument[]) =>
					resp.map(
						(cd: CompanyDocument) =>
							new CompanyDocument(cd.companyId, cd.fileName, cd.fileSize, cd.uploadDate, cd.status, cd.documentType)
					)
				)
			);
	}

	downloadCompanyDocument(
		id: number,
		documentType: CompanyDocumentType,
		fileName: string
	): Observable<HttpResponse<Blob>> {
		let params = new HttpParams();
		params = params.set("documentType", documentType.toString());
		params = params.set("fileName", fileName);
		return this.http.get(`${this.path}/company/${id}/download`, {
			observe: "response",
			responseType: "blob",
			params
		});
	}

	deleteCompanyDocument(id: number, document: CompanyDocument): Observable<any> {
		return this.http.delete(`${this.path}/company/${id}`, { body: document });
	}

	uploadCompanyFiles(id: number, documentType: CompanyDocumentType, fileHandles: FileHandle[]): Observable<any> {
		const url = `${this.path}/company/${id}`;
		return this.uploadFiles(fileHandles, documentType, url);
	}
	private uploadFiles(fileHandles: FileHandle[], documentType: CompanyDocumentType, url: string) {
		const formData = new FormData();
		fileHandles.forEach((fh) => {
			formData.append("upload", fh.file, fh.file.name);
		});
		formData.append("uploadDocumentType", documentType);
		const headers = new HttpHeaders();
		headers.append("Content-Type", "multipart/form-data");
		headers.append("Accept", "application/json");
		return this.http.post(url, formData, { headers });
	}

	uploadCompanyFilesToSalesForce(
		id: number,
		documentType: CompanyDocumentType,
		fileHandles: FileHandle[]
	): Observable<any> {
		const url = `${this.path}/company/${id}/:salesforce-upload`;
		return this.uploadFiles(fileHandles, documentType, url);
	}
}
