import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { combineLatest, filter, map, Subject, takeUntil } from "rxjs";
import { CompanyProfileRelationship } from "src/app/Models";
import { Store } from "@ngrx/store";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { LookupKeys } from "src/app/Models/Enums";
import { SharedText } from "src/app/Modules/COT-Module/Components/customer-setup/shared-text";
import { InfoDialogService } from "src/services";
import { PreferredPaymentInfoComponent } from "src/app/Modules/COT-Module/Components/customer-setup/preferred-payment/preferred-payment-info-dialog";

@Component({
	selector: "ls-customer-settings",
	templateUrl: "./customer-settings.component.html",
	styleUrls: ["./customer-settings.component.scss"]
})
export class CustomerSettingsComponent implements OnInit, OnDestroy {
	private componentTeardown$: Subject<any> = new Subject<any>();
	private _companyProfileRelationship?: CompanyProfileRelationship;
	get SharedText() {
		return SharedText;
	}
	showPaymentInfo() {
		this.dialogService.showDialog(PreferredPaymentInfoComponent);
	}
	@Input() set companyProfileRelationship(companyProfileRelationship: CompanyProfileRelationship | undefined) {
		if (companyProfileRelationship) {
			this._companyProfileRelationship = companyProfileRelationship;
			this.setFormGroup();
		}
	}
	get companyProfileRelationship(): CompanyProfileRelationship | undefined {
		return this._companyProfileRelationship;
	}
	@Input() readonly = false;
	public customerSettingsForm: FormGroup = new FormGroup({});
	public financeDisclosures: GenericLookup<string>[] = [];
	public preferredPayments: GenericLookup<string>[] = [];
	public static FORM_CONTROL_NAME = "customerSettings";
	public CUSTOMER_NOTIFICATION = "financeDisclosure";
	public PAYMENT_METHOD = "paymentMethod";
	public INSTRUMENT_APPROVAL_CONFIRMED = "instrumentApprovalConfirmed";
	public AUTOXCEL = "isAutoXcel";
	public FINANCING_EXPEDITED = "isFinancingExpedited";

	constructor(
		private store: Store<any>,
		private fgd: FormGroupDirective,
		private genericSelectors: GenericSelectors,
		private dialogService: InfoDialogService
	) {
		this.customerSettingsForm.addControl(this.CUSTOMER_NOTIFICATION, new FormControl(null, Validators.required), {
			emitEvent: false
		});
		this.customerSettingsForm.addControl(this.PAYMENT_METHOD, new FormControl(null, Validators.required), {
			emitEvent: false
		});
	}

	ngOnInit() {
		combineLatest([
			this.store.select(this.genericSelectors.selectLookup(LookupTables.PreferredPaymentMethod)),
			this.store.select(this.genericSelectors.selectLookupByKey(LookupKeys.FinancialDisclosureYesNo))
		])
			.pipe(
				takeUntil(this.componentTeardown$),
				filter(([payments, disclosures]) => !!payments && !!disclosures),
				map(([payments, disclosures]) => {
					this.preferredPayments = payments!;
					this.financeDisclosures = disclosures!;
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	setFormGroup() {
		this.customerSettingsForm
			.get(this.CUSTOMER_NOTIFICATION)
			?.patchValue(this.companyProfileRelationship?.financeDisclosureYesNoId, { emitValue: false });
		this.customerSettingsForm
			.get(this.PAYMENT_METHOD)
			?.patchValue(this.companyProfileRelationship?.preferredPaymentMethodId, { emitValue: false });

		this.fgd.form?.addControl(CustomerSettingsComponent.FORM_CONTROL_NAME, this.customerSettingsForm, {
			emitEvent: false
		});
	}
}
