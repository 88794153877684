import { Component, EventEmitter, Input, Output } from "@angular/core";
import { InfoDialogService } from "src/services";

@Component({
	selector: "ls-info-dialog-icon",
	template: `<span
		class="ls-ml-1 material-icons li-tooltip-icon ls-icon-primary-tooltip hoverable"
		(click)="mouseClick($event)"
		>info</span
	>`
})
export class InfoDialogIconComponent {
	constructor(private dialogService: InfoDialogService) {}
	@Input() header?: string;
	@Input() content?: string;
	@Output() iconClick: EventEmitter<MouseEvent> = new EventEmitter();

	mouseClick(event: MouseEvent): void {
		if (!this.header && !this.content) this.iconClick.emit(event);
		else this.dialogService.showInfoDialog(this.header, this.content);
	}
}
