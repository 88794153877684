import {
	BrowserCacheLocation,
	InteractionType,
	IPublicClientApplication,
	LogLevel,
	PublicClientApplication
} from "@azure/msal-browser";
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { B2CQueryParams } from "../app/Models";
import { LocalStorageService } from "../Utils";

export function loggerCallback(logLevel: LogLevel, message: string) {
	console.log(message);
}

export function MSALInstanceFactory(clientId: string, policy: string): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: clientId,
			authority: `https://raistoneb2c.b2clogin.com/raistoneb2c.onmicrosoft.com/${policy}`,
			redirectUri: origin + "/authorized",
			postLogoutRedirectUri: origin + "/home",
			knownAuthorities: ["raistoneb2c.b2clogin.com"]
		},
		cache: {
			cacheLocation: BrowserCacheLocation.SessionStorage,
			storeAuthStateInCookie: true,
			secureCookies: true,
			cacheMigrationEnabled: true
		},
		system: {
			loggerOptions: {
				loggerCallback: loggerCallback,
				logLevel: LogLevel.Info,
				piiLoggingEnabled: false
			}
		}
	});
}

export function MSALInterceptorConfigFactory(host: string, scopes: string[]): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string> | null>();
	protectedResourceMap.set(`${host}/api/validation`, null);
	protectedResourceMap.set(`${host}/api/onboarding/accountSubmission`, null);
	protectedResourceMap.set(`${host}/api/annualrevenuerange`, null);
	protectedResourceMap.set(`${host}/api/featureflag/onboarding`, null);
	protectedResourceMap.set(`${host}/api/phonecodes`, null);
	protectedResourceMap.set(`${host}`, scopes);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
		authRequest: () => {
			const localStorageService = new LocalStorageService();
			const companyId = localStorageService.getCompanyId();
			const email = localStorageService.getEmailAddress();
			const params = new B2CQueryParams(companyId, email, localStorageService.getB2CSignupState());
			return {
				scopes,
				extraQueryParameters: params.toParamsObject()
			};
		}
	};
}

export function MSALGuardConfigFactory(scopes: string[]): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: () => {
			const localStorageService = new LocalStorageService();
			const companyId = localStorageService.getCompanyId();
			const email = localStorageService.getEmailAddress();
			const params = new B2CQueryParams(companyId, email, localStorageService.getB2CSignupState());
			return {
				scopes,
				extraQueryParameters: params.toParamsObject()
			};
		}
	};
}
