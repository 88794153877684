import { Component } from "@angular/core";

@Component({
	selector: "ls-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"]
})
export class FooterComponent {
	currentYear = new Date().getFullYear();
}
