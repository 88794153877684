import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { map, takeUntil } from "rxjs/operators";
import { combineLatest, filter, Subject } from "rxjs";
import { Actions, ofType } from "@ngrx/effects";
import { CompanyProfile, CompanyProfileRelationship, OnboardCompanyProgress } from "../../../../Models";
import { QuestionnaireComponent } from "../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { Breadcrumb } from "../../../../Models/Interfaces/Breadcrumb";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../Services";

@Component({ selector: "ls-cpr", template: "" })
export abstract class CustomerRelationshipBaseComponent extends RouteStepDataBaseComponent implements OnDestroy {
	public componentTeardown$ = new Subject();
	companyProfileRelationship?: CompanyProfileRelationship;
	public breadcrumbs?: Array<Breadcrumb> = [];
	childForm?: QuestionnaireComponent;

	protected constructor(
		public router: Router,
		public store: Store,
		public actions$: Actions,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors
	) {
		super(routeStepDataService);
		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyProfileRelationshipActions.companyProfileRelationshipSaveSuccessful),
				filter(() => this.companyProfileRelationship?.relationshipCompanyId !== undefined),
				map(() => {
					if (this.activeRouteData!.substep! > this.companyProfileRelationship!.currentSubStep!) {
						this.companyProfileRelationship!.currentSubStep = this.activeRouteData!.substep!;
						this.store.dispatch(
							CompanyProfileRelationshipActions.updateCustomerProgress({
								companyProfileRelationship: this.companyProfileRelationship!
							})
						);
					}
					this.navigateToNextScreen();
					this.childForm!.isNextButtonDisabled = false;
				})
			)
			.subscribe();

		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress),
			this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
		])
			.pipe(
				filter(([cp, ocp, cpr]) => !!cp && !!ocp && !!cpr),
				takeUntil(this.componentTeardown$),
				map(([cp, ocp, cpr]) => {
					this.setBreadcrumbs(cp!, ocp!, cpr!);
				})
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyProfileRelationshipActions.companyProfileRelationshipSaveUnsuccessful),
				map(() => {
					this.childForm!.isNextButtonDisabled = false;
				})
			)
			.subscribe();
	}

	public setBreadcrumbs(cp: CompanyProfile, ocp: OnboardCompanyProgress, cpr: CompanyProfileRelationship) {
		const path = cp?.isCustomersInProgress ? "review" : "manual";

		this.breadcrumbs = [
			{
				routerPath: `/onboarding/${ocp!.onboardingConfigurationId}/customer-setup/${path}`,
				displayText: "Customer Setup"
			}
		];
		const name = cpr?.relationshipCompanyName;
		if (name !== null && name !== undefined && name?.trim() !== "") {
			this.breadcrumbs.push({ routerPath: "", displayText: name });
		}
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	public abstract continue(): void;
	public navigateToNextScreen(index = 0) {
		if (
			this.activeRouteData?.isCustomerSetupCompleteStep &&
			!this.companyProfileRelationship!.isCustomerSetupComplete
		) {
			this.companyProfileRelationship!.isCustomerSetupComplete = true;

			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: this.companyProfileRelationship!
				})
			);
		}
		this.nav(
			index,
			new Map<string, string | number>([
				["relationshipCompanyId", this.companyProfileRelationship!.relationshipCompanyId!]
			])
		);
	}

	public abstract initData(): void;
}
