<ls-questionnaire-toggle #questionnaireForm *ngIf="display(BODY)" [toggleLabel]="toggleLabel"
  [(enabled)]=isFinanceExpedited [toggledState]="toggledState" [displayConfig]="config(BODY,'q1')"
  [breadcrumbs]="breadcrumbs" (buttonClicked)="continue()">
  <div>
    <ls-text extraClasses="ls-mb-2" [displayConfig]="config(BODY,'q1-p1')">
      <ls-info-dialog-icon [header]="config(BODY,'tt-hl1')?.text"
        [content]="config(BODY,'tt-p1')?.text"></ls-info-dialog-icon>
    </ls-text>
    <ls-text extraClasses="ls-mb-2" [displayConfig]="config(BODY,'q1-p2')"></ls-text>
    <ls-text extraClasses="ls-mb-0" [displayConfig]="config(BODY,'q1-p3')"></ls-text>
  </div>
</ls-questionnaire-toggle>