import { DisplayConfig } from "./DisplayConfig";
import { RadioButtonTooltip } from "./RadioButtonTooltip";
/// <summary>
/// This represents a section of content in a component.
/// </summary>
export class Layout {
	/// <summary>
	/// Controls visibility of a section of html in a component
	/// </summary>
	display = true;
	/// <summary>
	///  key: id of the element
	///  value: display config of the element
	/// </summary>
	blocks?: Map<string, DisplayConfig>;
	/// <summary>
	/// key: the id of the lookup value
	/// value: text to be displayed
	/// </summary>
	toolTips?: Map<string | number, RadioButtonTooltip>;
	/**
	 *
	 */
	constructor(blocks?: any, toolTips?: any, display = true) {
		this.display = display;
		if (blocks) {
			this.parseBlocks(blocks);
		}
		if (toolTips) {
			this.parseToolTips(toolTips);
		}
	}

	parseBlocks(blocks: any) {
		if (blocks instanceof Map) {
			this.blocks = blocks;
			return;
		}
		const result = new Map<string, DisplayConfig>();
		Object.keys(blocks).forEach((key) => {
			const dc = blocks[key] as DisplayConfig;
			const displayConfig = new DisplayConfig(dc.text, dc.textStyle, dc.display);
			result.set(key, displayConfig);
		});
		this.blocks = result;
	}

	parseToolTips(toolTips: any) {
		if (toolTips instanceof Map) {
			this.toolTips = toolTips;
			return;
		}
		const result = new Map<string | number, RadioButtonTooltip>();
		Object.keys(toolTips).forEach((key) => {
			result.set(key, toolTips[key]);
		});
		this.toolTips = result;
	}
}
