import { Component, OnDestroy, OnInit, SecurityContext } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { map, takeUntil } from "rxjs/operators";
import { filter, forkJoin, Observable, of, Subject, take } from "rxjs";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { Actions, ofType } from "@ngrx/effects";
import { CompanyDocument, LsHttpErrorResponse } from "../Models";
import { CompanyDocumentType } from "../Models/Enums";
import { FileUploadError, SortEvent } from "../Models/Interfaces";
import { IColumnHeader } from "../Models/Interfaces/IColumnHeader";
import { DeleteDocumentComponent } from "../Modules/COT-Module/Modals";
import { FileHandle } from "../../Elements/upload-widget/FileHandle";
import { CompanyDocumentActions } from "../Modules/COT-Module/OnboardingStateManagement/CompanyDocument/company-document-actions";
import { ModalActions } from "./ModalActions";
import { DomSanitizer, SafeValue } from "@angular/platform-browser";
import { RouteStepDataBaseComponent } from "./route-step-data-base.component";
import { RouteStepDataService } from "../Modules/COT-Module/Services";
import { SortDirection } from "@limestone/ls-shared-modules";
import { FileUtilityService } from "../../services/file-utility.service";
import { CompanyDocumentUploadError, UploadStatus } from "../Models/Enums";

@Component({ selector: "ls-rmh", template: "" })
export abstract class FileUploadBaseComponent extends RouteStepDataBaseComponent implements OnInit, OnDestroy {
	public componentTeardown$ = new Subject();
	public files?: CompanyDocument[];
	public validFiles?: CompanyDocument[] = [];
	public erroredFiles?: CompanyDocument[] = [];
	public filesForSalesForce: FileHandle[] = [];
	public allowedFileExtensionsMap: Map<string, boolean>;
	public fileUtilityService: FileUtilityService = new FileUtilityService();
	public allowedFileExtensions: string[];
	public displayableFileExtensions: string[];
	get errors() {
		return this.files?.map((f) => f.error).filter((f) => !!f) ?? [];
	}
	public maxFileSize = 50; // In MB
	public maxFileCount = 10;
	public disabled = false;
	public submitted = false;
	private companyId?: number;
	private docType?: CompanyDocumentType;

	public uploadErrors: Map<CompanyDocumentUploadError, string[]> = new Map<CompanyDocumentUploadError, string[]>();
	public duplicateFiles: string[] = [];
	public unsupportedFileTypeFiles: string[] = [];
	public oversizedFiles: string[] = [];
	public MAX_FILE_COUNT_STRING = this.sanitizer.sanitize(
		SecurityContext.HTML,
		"You’ve reached the maximum amount of files."
	);

	constructor(
		public router: Router,
		public store: Store,
		public dialog: MdbModalService,
		public actions$: Actions,
		public sanitizer: DomSanitizer,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
		this.docType = this.activeRouteData!.docType!;
		this.allowedFileExtensionsMap = FileUtilityService.getAllowedFileExtensions();
		this.allowedFileExtensions = Array.from(this.allowedFileExtensionsMap.keys());
		this.displayableFileExtensions = Array.from(
			new Map([...this.allowedFileExtensionsMap.entries()].filter(([, value]) => value)).keys()
		);
	}

	ngOnInit() {
		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyDocumentActions.saveUnsuccessful),
				map((act) => {
					const errMap = act.result.errors as Map<CompanyDocumentUploadError, string[]>;
					this.handleErrorMap(errMap);
				})
			)
			.subscribe();
	}
	override nav() {
		this.uploadFilesToSalesForce();
		super.nav();
	}
	public handleErrorMap(errMap: Map<CompanyDocumentUploadError, string[]>) {
		errMap.forEach((erroredFiles: string[], error: CompanyDocumentUploadError) => {
			let errString: SafeValue | string | null = null;
			erroredFiles.forEach((file) => {
				errString = this.getErrorMessage(error, file);
				this.files?.forEach((f) => {
					if (f.status === UploadStatus.IN_PROGRESS && file === f.fileName) {
						f.status = UploadStatus.FAILED;
						f.error = errString;
					}
				});
			});
		});

		this.checkIfShouldDisable();
	}

	private getErrorMessage(error: CompanyDocumentUploadError, fileName: string) {
		let errString: string | SafeValue | null = null;
		switch (error) {
			case CompanyDocumentUploadError.DUPLICATE_FILE_EXISTS: {
				errString = this.sanitizer.sanitize(
					SecurityContext.HTML,
					`You’ve already uploaded <strong>${fileName}</strong>. Please try again by uploading a different file.`
				);

				break;
			}
			case CompanyDocumentUploadError.FILE_SIZE_EXCEEDED: {
				errString = this.sanitizer.sanitize(
					SecurityContext.HTML,
					`<strong>${fileName}</strong> exceeds the maximum file size. Please upload documents smaller than 50 MB.`
				);

				break;
			}
			case CompanyDocumentUploadError.UNSUPPORTED_FILE_TYPE: {
				errString = this.sanitizer.sanitize(
					SecurityContext.HTML,
					`The file type for <strong>${fileName}</strong> isn’t permitted. Try again with any of the allowed file types: ${this.allowedFileExtensions.join(", ")}.`
				);

				break;
			}
			case CompanyDocumentUploadError.MAX_FILE_COUNT: {
				errString = this.MAX_FILE_COUNT_STRING;
				break;
			}
		}

		return errString;
	}

	public getDocType(): CompanyDocumentType {
		return this.docType!;
	}

	public getComponentTearDown(): Subject<any> {
		return this.componentTeardown$;
	}

	public setCompanyId(id: number): void {
		this.companyId = id;
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	public navTo() {}

	public handleSortChange(sort: SortEvent) {
		const sortOrder = sort.sort.direction;
		const sortColumn = sort.sort.active ?? "fileName";

		if (!CompanyDocument.ColumnNames().some((c: IColumnHeader) => c.relatedField === sortColumn)) {
			throw new Error(`Column ${sortColumn} does not exist on CompanyDocument.`);
		}
		if (!this.files || this.files.length === 0) {
			return;
		}

		const isDescending = sortOrder === SortDirection.DESC;
		const isFileSizeColumn = sortColumn === "fileSize";

		this.files.sort((a: any, b: any) => {
			const valueA = isFileSizeColumn
				? FileUtilityService.convertFileSizeToBytes(a[sortColumn])
				: a[sortColumn]?.toString().toLowerCase();

			const valueB = isFileSizeColumn
				? FileUtilityService.convertFileSizeToBytes(b[sortColumn])
				: b[sortColumn]?.toString().toLowerCase();

			if (valueA < valueB) return isDescending ? 1 : -1;
			if (valueA > valueB) return isDescending ? -1 : 1;
			return 0;
		});
	}

	public handleError(error: FileUploadError) {
		this.files?.push(
			new CompanyDocument(
				this.companyId,
				error.file.name,
				FileUtilityService.convertFileSize(error.file.size),
				new Date(),
				UploadStatus.FAILED,
				this.docType,
				error.errorMessage
			)
		);
	}

	public openFile(file: CompanyDocument) {
		this.store.dispatch(
			CompanyDocumentActions.downloadFile({
				companyId: file.companyId!,
				documentType: this.docType!,
				fileName: file.fileName!
			})
		);
	}

	public deleteDocument(document: CompanyDocument) {
		this.filesForSalesForce = this.filesForSalesForce.filter((f) => f.file.name !== document.fileName);
		if (document.status === UploadStatus.FAILED) {
			this.files = this.files?.filter((f) => f.key !== document.key);
		} else {
			this.dialog
				.open(DeleteDocumentComponent, {
					modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
					ignoreBackdropClick: true,
					data: { fileName: document.fileName }
				})
				.onClose.pipe(
					filter((result: ModalActions) => result === ModalActions.PRIMARY),
					take(1),
					map(() => {
						this.store.dispatch(
							CompanyDocumentActions.deleteCompanyDocument({
								id: document.companyId!,
								documentType: document.documentType!,
								document
							})
						);
					})
				)
				.subscribe();
		}
		this.checkIfShouldDisable();
	}

	public uploadFiles(files: FileHandle[]) {
		// Check if file count is over limit.
		if (this.fileCountOverLimit(files) || this.submitted) {
			files.forEach((f) => {
				const cd = new CompanyDocument(
					this.companyId,
					f.file.name,
					FileUtilityService.convertFileSize(f.file.size),
					new Date(),
					UploadStatus.FAILED,
					this.docType
				);
				cd.error = this.getErrorMessage(CompanyDocumentUploadError.MAX_FILE_COUNT, cd.fileName!);
				cd.errorType = CompanyDocumentUploadError.MAX_FILE_COUNT;
				this.files?.push(cd);
			});
			this.handleErrorMap(this.uploadErrors);
			return;
		}
		const obvs: Observable<{
			processedFile: boolean | undefined;
			fileHandle: FileHandle;
		}>[] = [];
		files.forEach((f) => {
			this.fileUtilityService
				.fileTypeSupported(f, this.allowedFileExtensions)
				.pipe(
					takeUntil(this.componentTeardown$),
					map((result) => {
						if (f) {
							const processedFile = result;
							const file = f;
							const match = new Array(obvs.values).find((file: any) => {
								file.fileHandle.file.name === f.file.name;
							});
							if (!match) obvs.push(of({ processedFile: result, fileHandle: f }));
							this.validateFiles(processedFile, file);
						}
					})
				)
				.subscribe(() => {
					forkJoin(obvs)
						.pipe(
							takeUntil(this.componentTeardown$),
							map(() => {
								if (obvs.length === files.length) {
									this.handleProcessedAndValidatedFiles(files);
									this.checkIfShouldDisable();
								}
							})
						)
						.subscribe();
				});
		});
	}
	public uploadFilesToSalesForce() {
		if (this.filesForSalesForce.length > 0) {
			this.store.dispatch(
				CompanyDocumentActions.uploadFilesToSalesForce({
					companyId: this.companyId!,
					documentType: this.docType!,
					files: this.filesForSalesForce
				})
			);
		}
	}
	public handleProcessedAndValidatedFiles(files: FileHandle[]) {
		const filesToUpload = files.filter((f) => this.validFiles?.find((validFile) => f.file.name === validFile.fileName));
		const failedFiles = files.filter((f) =>
			this.erroredFiles?.find((erroredFile) => f.file.name === erroredFile.fileName)
		);

		if (filesToUpload.length > 0) {
			filesToUpload.forEach((file) => {
				this.filesForSalesForce.push(file);
			});
			this.store.dispatch(
				CompanyDocumentActions.uploadFiles({
					companyId: this.companyId!,
					documentType: this.docType!,
					files: filesToUpload
				})
			);
		}

		if (this.erroredFiles!.length > 0 && filesToUpload.length === 0) {
			const errorResponses = this.createErrorResponses(this.uploadErrors);
			errorResponses.forEach((e) => {
				this.dispatchSaveUnsuccessful(e, failedFiles);
			});
		}
	}
	public validateFiles(supportedFile: boolean | undefined, file: FileHandle) {
		let documentInErrorState = false;
		const companyDocument: CompanyDocument = new CompanyDocument(
			this.companyId,
			file.file.name,
			FileUtilityService.convertFileSize(file.file.size),
			new Date(),
			UploadStatus.IN_PROGRESS,
			this.docType
		);
		// Check if supportedFile is false, if false, add to unsupportedFileTypeFiles else perform other checks

		if (!supportedFile) {
			this.unsupportedFileTypeFiles.push(file.file.name);
			this.uploadErrors.set(CompanyDocumentUploadError.UNSUPPORTED_FILE_TYPE, this.unsupportedFileTypeFiles);
			this.erroredFiles!.push(companyDocument);
			companyDocument.error = this.getErrorMessage(
				CompanyDocumentUploadError.UNSUPPORTED_FILE_TYPE,
				companyDocument.fileName!
			);
			companyDocument.errorType = CompanyDocumentUploadError.UNSUPPORTED_FILE_TYPE;
		} else {
			// Check if duplicate files exist.
			const duplicateFile = this.getDuplicateFilesIfExists(companyDocument);
			if (duplicateFile != undefined) {
				documentInErrorState = true;
				this.duplicateFiles.push(duplicateFile);
				companyDocument.status = UploadStatus.FAILED;
				this.uploadErrors.set(CompanyDocumentUploadError.DUPLICATE_FILE_EXISTS, [duplicateFile.fileName]);
				companyDocument.error = this.getErrorMessage(
					CompanyDocumentUploadError.DUPLICATE_FILE_EXISTS,
					companyDocument.fileName!
				);
				companyDocument.errorType = CompanyDocumentUploadError.DUPLICATE_FILE_EXISTS;
			}
			// Check if file size is over limit.

			if (FileUtilityService.isFileSizeOverLimit(file, this.maxFileSize)) {
				documentInErrorState = true;
				this.oversizedFiles.push(file.file.name);
				this.uploadErrors.set(CompanyDocumentUploadError.FILE_SIZE_EXCEEDED, this.oversizedFiles);
				companyDocument.error = this.getErrorMessage(
					CompanyDocumentUploadError.FILE_SIZE_EXCEEDED,
					companyDocument.fileName!
				);
				companyDocument.errorType = CompanyDocumentUploadError.FILE_SIZE_EXCEEDED;
			}

			if (documentInErrorState) {
				this.erroredFiles!.push(companyDocument);
			} else {
				this.validFiles!.push(companyDocument);
			}
		}
		if (!this.files?.some((f) => f.key === companyDocument.key)) {
			this.files?.push(companyDocument);
		}
	}

	private createErrorResponses(uploadErrors: Map<string, string[]>): LsHttpErrorResponse[] {
		const errorResponses: LsHttpErrorResponse[] = [];
		for (const [key, value] of uploadErrors) {
			value.forEach((e) => {
				const errResp = new LsHttpErrorResponse(undefined, undefined, undefined, undefined, undefined);
				errResp.errors = new Map<string, string[]>([[key, [e]]]);
				errorResponses.push(errResp);
			});
		}
		return errorResponses;
	}

	public rebuildFilesListAfterUpload(uploadedFiles: CompanyDocument[]): CompanyDocument[] {
		const failedFiles = this.files?.filter(
			(f) => f.status == UploadStatus.FAILED && f.errorType !== CompanyDocumentUploadError.UNSUPPORTED_FILE_TYPE
		);
		this.erroredFiles = this.erroredFiles?.filter(
			(f) => f.errorType !== CompanyDocumentUploadError.UNSUPPORTED_FILE_TYPE && f.status == UploadStatus.FAILED
		);
		if (this.uploadErrors.has(CompanyDocumentUploadError.UNSUPPORTED_FILE_TYPE)) {
			this.uploadErrors.delete(CompanyDocumentUploadError.UNSUPPORTED_FILE_TYPE);
		}
		return [...(failedFiles ?? []), ...(uploadedFiles ?? [])];
	}

	private dispatchSaveUnsuccessful(errResp: LsHttpErrorResponse, files: FileHandle[]) {
		this.store.dispatch(CompanyDocumentActions.saveUnsuccessful({ files, result: errResp }));
	}

	private getDuplicateFilesIfExists(file: CompanyDocument): any {
		return this.files?.find((f) => f.fileName === file.fileName && f.status !== UploadStatus.FAILED);
	}

	public fileCountOverLimit(files: FileHandle[]): boolean {
		const completeOrInprogressFiles = this.getCompletedOrInProgressFiles();
		if (completeOrInprogressFiles) {
			return completeOrInprogressFiles.length + files.length > this.maxFileCount;
		} else {
			return files.length > this.maxFileCount;
		}
	}

	private getCompletedOrInProgressFiles(): CompanyDocument[] {
		return this.files?.filter((file) => [UploadStatus.IN_PROGRESS, UploadStatus.COMPLETE].includes(file.status!)) || [];
	}

	private checkIfShouldDisable(): void {
		this.disabled = this.getCompletedOrInProgressFiles().length === this.maxFileCount;
	}

	public abstract initData(): void;
}
