import { Component } from "@angular/core";
import { ModalActions } from "../../../../Core";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Contact } from "../../../../Models";
import { GenericLookup } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-invite-user-persuasion",
	templateUrl: "./invite-user.component.html",
	styleUrls: ["./invite-user.component.scss"]
})
export class InviteUserComponent {
	roles?: GenericLookup<string>[];
	companyName?: string;
	CONTACT = "contact";
	ROLE_CONTROL = "role";

	public form: FormGroup = new FormGroup<any>({});

	constructor(public modalRef: MdbModalRef<InviteUserComponent>) {
		this.form = new FormGroup<any>({
			contact: new FormControl(null, Validators.required),
			role: new FormControl(null, Validators.required)
		});
	}

	public get ModalActions(): typeof ModalActions {
		return ModalActions;
	}

	public close(action: ModalActions): void {
		const result: ModalResult = {
			action: action
		};
		if (action === this.ModalActions.PRIMARY) {
			if (this.form.valid) {
				const contact: Contact = this.form.get(this.CONTACT)?.value;
				result.user = {
					firstName: contact.firstName,
					lastName: contact.lastName,
					email: contact.email,
					role: this.form.get(this.ROLE_CONTROL)?.value
				};
			}
		}
		this.modalRef.close(result);
	}

	public getRoleName(lsRole?: GenericLookup<string>): string {
		if (lsRole) {
			if (lsRole.name!.split(" ").length > 1) {
				return lsRole.name!.split(" ")[1];
			}
		}
		return "";
	}
}

export class ModalResult {
	action?: ModalActions;
	user?: UserInvite;
}

export class UserInvite {
	firstName?: string;
	lastName?: string;
	email?: string;
	role?: string;
}
