import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, mergeMap, switchMap } from "rxjs";
import { LsHttpErrorResponse } from "src/app/Models";
import { ValidationService } from "../../Services";
import { ValidationActions } from "./validation-actions";

@Injectable()
export class ValidationEffects {
	constructor(
		private actions$: Actions,
		private validationService: ValidationService
	) {}

	validatePhone = createEffect(() =>
		this.actions$.pipe(
			ofType(ValidationActions.validatePhone),
			mergeMap((act) =>
				this.validationService.validate(act.phone).pipe(
					switchMap(() => [
						ValidationActions.validationFailure({
							id: act.id,
							fieldValue: act.phone.number,
							validationType: act.phone.type,
							result: new LsHttpErrorResponse()
						})
					]),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						const errResp = new LsHttpErrorResponse(
							error.error.status,
							error.error.title,
							error.error.title,
							error.error.type,
							error.error.errors
						);
						return [
							ValidationActions.validationFailure({
								id: act.id,
								fieldValue: act.phone.number,
								validationType: act.phone.type,
								result: errResp
							})
						];
					})
				)
			)
		)
	);

	validateEmail = createEffect(() =>
		this.actions$.pipe(
			ofType(ValidationActions.validateEmail),
			mergeMap((act) =>
				this.validationService.validate(act.email).pipe(
					switchMap(() => [
						ValidationActions.validationFailure({
							id: act.id,
							validationType: act.email.type,
							result: new LsHttpErrorResponse()
						})
					]),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						const errResp = new LsHttpErrorResponse(
							error.error.status,
							error.error.title,
							error.error.title,
							error.error.type,
							error.error.errors
						);
						return [
							ValidationActions.validationFailure({ id: act.id, validationType: act.email.type, result: errResp })
						];
					})
				)
			)
		)
	);

	validateIban$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ValidationActions.validateBankAcc),
			mergeMap((act) =>
				this.validationService.validate(act.bankAccount).pipe(
					switchMap(() => [
						ValidationActions.validationFailure({
							id: `${act.bankAccount.bankAccount?.id}` ?? "",
							validationType: act.bankAccount.type
						})
					]),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						const errResp = new LsHttpErrorResponse(
							error.error.status,
							error.error.title,
							error.error.title,
							error.error.type,
							error.error.errors
						);
						return [
							ValidationActions.validationFailure({
								id: `${act.bankAccount.bankAccount?.id}` ?? "",
								validationType: act.bankAccount.type,
								result: errResp
							})
						];
					})
				)
			)
		)
	);
}
