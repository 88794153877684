import { ComponentType } from "@angular/cdk/portal";
import { Injectable, TemplateRef } from "@angular/core";
import { MdbModalRef, MdbModalService } from "mdb-angular-ui-kit/modal";
import { InfoDialogComponent } from "src/Elements/tooltip/info-dialog.component";

@Injectable()
export class InfoDialogService {
	templateContent: TemplateRef<any> | null = null;
	constructor(private dialog: MdbModalService) {}
	public modalRef?: MdbModalRef<any>;
	public close() {
		if (this.modalRef) this.modalRef.close();
	}
	public showInfoDialog(header?: string, content?: string) {
		this.modalRef = this.dialog.open(InfoDialogComponent, {
			modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
			ignoreBackdropClick: false,
			data: { header: header ?? "", content: content ?? "" }
		});
	}
	public showDialog(component: ComponentType<any>): MdbModalRef<any> {
		this.modalRef = this.dialog.open(component, {
			modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
			ignoreBackdropClick: false
		});
		return this.modalRef;
	}
}
