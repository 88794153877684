import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, map, takeUntil } from "rxjs";
import { AuthorizeRolesService } from "../../../../../services";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { CompanyProfileSelectors, SalesforceSupportSelectors } from "src/app/AppStateManagement";
import { filter } from "rxjs/operators";
import { FeatureFlags, FeatureFlagService, LsRole } from "@limestone/ls-shared-modules";
import { EndSessionRequest } from "@azure/msal-browser";
import { MsalService } from "@azure/msal-angular";

@Component({
	selector: "ls-nav-menu",
	templateUrl: "./nav-menu.component.html",
	styleUrls: ["./nav-menu.component.scss"]
})
export class NavMenuComponent implements OnInit, OnDestroy {
	private componentTeardown$: Subject<any> = new Subject<any>();
	public activeIndex?: number;
	public navItems: LsNavItem[] = [
		{
			label: "Applications",
			route: "./applications",
			icon: "apps",
			allowedRoles: [LsRole.ECONTRIB, LsRole.EADMIN]
		},
		{
			label: "Support",
			route: "./support",
			icon: "support",
			allowedRoles: [LsRole.ECONTRIB, LsRole.EADMIN],
			notifications: 0
		},
		{
			label: "Users",
			route: "./users",
			icon: "people",
			allowedRoles: [LsRole.EADMIN],
			notifications: 0
		}
	];

	public expanded = false;
	public isOnboardingV103Enabled = false;
	constructor(
		public authRolesService: AuthorizeRolesService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private store: Store,
		private companyProfileSelectors: CompanyProfileSelectors,
		private salesforceSupportSelectors: SalesforceSupportSelectors,
		private authService: MsalService,
		private featureFlagService: FeatureFlagService
	) {}

	ngOnInit() {
		this.featureFlagService
			.isEnabled(FeatureFlags.Onboarding_V1_0_3)
			.pipe(
				filter((isEnabled) => !!isEnabled),
				takeUntil(this.componentTeardown$),
				map((isEnabled) => {
					this.isOnboardingV103Enabled = isEnabled;
				})
			)
			.subscribe();
		this.store
			.select(this.companyProfileSelectors.selectCompanyProfile)
			.pipe(
				filter((cp) => !!cp),
				takeUntil(this.componentTeardown$),
				map((cp) => {
					this.navItems[2].notifications = cp!.users!.length < 2 ? 1 : 0;
				})
			)
			.subscribe();

		this.store
			.select(this.salesforceSupportSelectors.selectCases)
			.pipe(
				filter((c) => !!c),
				takeUntil(this.componentTeardown$),
				map((cases) => {
					this.navItems[1].notifications = cases
						?.filter((c) => c.newActivity)
						.reduce((newCount) => {
							return (newCount += 1);
						}, 0);
				})
			)
			.subscribe();

		this.navToDefault();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	navToDefault() {
		const childRoute = this.navItems[0].route!.split("./")[0];
		if (this.activeIndex === undefined && !this.router.url.includes(childRoute)) {
			this.router.navigate([this.navItems[0].route], { relativeTo: this.activatedRoute });
		}
	}

	checkRoles(roles: LsRole[] | undefined): boolean {
		return this.authRolesService.checkRoles(roles!);
	}

	setActiveIndex(index: number) {
		this.activeIndex = index;
		if (this.expanded) this.toggleExpand();
	}

	public toggleExpand(): void {
		this.expanded = !this.expanded;
	}

	public logOut() {
		const request: EndSessionRequest = {
			account: this.authService.instance.getActiveAccount(),
			postLogoutRedirectUri: window.origin + "/logout",
			idTokenHint: this.authService.instance.getActiveAccount()?.idToken
		};
		this.authService.logout(request);
	}
}

class LsNavItem {
	label?: string;
	route?: string;
	icon?: string;
	allowedRoles?: LsRole[];
	notifications?: number;
}
