import { Component } from "@angular/core";
import { Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { filter, map, Observable, takeUntil } from "rxjs";
import { CompanyProfile, DisplayConfig, HtmlContent, Layout } from "src/app/Models";
import { CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { AddressType, GenericSelectors, StateProvince } from "@limestone/ls-shared-modules";
import { CompanyDetailsBaseComponent } from "../../company-details-base.component";
import { combineLatest } from "rxjs";
import { RouteStepDataService } from "../../../Services";
import { TextStyle } from "src/app/Models/Enums";
import { SharedText } from "../../customer-setup/shared-text";
@Component({
	selector: "ls-state-of-formation",
	templateUrl: "./state-of-formation.component.html",
	styleUrls: ["./state-of-formation.component.scss"]
})
export class StateOfFormationComponent extends CompanyDetailsBaseComponent {
	constructor(
		public store: Store<any>,
		private companyProfileSelectors: CompanyProfileSelectors,
		private genericSelectors: GenericSelectors,
		public router: Router,
		public routeStepDataService: RouteStepDataService
	) {
		super(store, router, routeStepDataService);
		this.content = new HtmlContent({ layout: this.DefaultLayout });
		this.setupControl(this.form, this.STATE_OF_FORMATION, this.companyProfile?.formationStateCode, Validators.required);
	}

	public STATE_OF_FORMATION = "stateOfFormation";
	public stateProvinces: StateProvince[] = [];

	onClick() {
		const updatedCompanyProfile = copy(this.companyProfile!);
		updatedCompanyProfile.formationStateCode = this.form.get(this.STATE_OF_FORMATION)?.value;
		this.emitSave(updatedCompanyProfile);
		this.nav();
	}

	init(): Observable<CompanyProfile | undefined> {
		return combineLatest([
			this.store.select(this.genericSelectors.selectStateProvinces),
			this.store.select(this.companyProfileSelectors.selectCompanyProfile)
		]).pipe(
			filter(([states, cp]) => !!states && !!cp),
			takeUntil(this.componentTeardown$),
			map(([states, cp]) => {
				const primaryAddress = cp!.addresses?.find(
					(companyAddress) => companyAddress.addressType?.id === AddressType.PRIMARY
				);

				this.stateProvinces = states!.filter((s) => s.country === primaryAddress?.address?.country?.code);
				this.companyProfile = cp;
				const isValidStateCode = this.stateProvinces.some((state) => state.code === cp?.formationStateCode);
				this.form
					.get(this.STATE_OF_FORMATION)
					?.setValue(isValidStateCode ? cp?.formationStateCode : null, Validators.required),
					{ emitEvent: false };
				return cp;
			})
		);
	}
	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig(SharedText.CustomerSetup.letsGetTheRest, TextStyle.HEADLINE_LARGE)]
				])
			)
		],
		[
			this.STATE_OF_FORMATION,
			new Layout(
				new Map<string, DisplayConfig>([
					["q1", new DisplayConfig("What’s your company’s state or province of formation?", TextStyle.TITLE_MEDIUM)],
					["label", new DisplayConfig("State/Province")]
				])
			)
		]
	]);
}
