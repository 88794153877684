import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, mergeMap, Observable, of, take } from "rxjs";
import { OnboardCompanyProgress } from "src/app/Models";
import { HttpService } from "src/services/HTTP";
import { CompanyProfileActions, CompanyProfileSelectors, StepperActions } from "../../../AppStateManagement";
import { Store } from "@ngrx/store";

@Injectable()
export class CompanyProgressService extends HttpService {
	constructor(
		public http: HttpClient,
		private store: Store,
		private companyProfileSelectors: CompanyProfileSelectors
	) {
		super(http);
		this.path = "/api/onboardcompanyprogress/companyProfile";
	}

	/*
	 * Fetches the onboard company progress from the state if product and flow id match; otherwise, fetches from server.
	 */
	getOnboardCompanyProgress(
		companyId: number,
		productId?: string,
		flowId?: string,
		routeKey?: string
	): Observable<OnboardCompanyProgress> {
		return this.GetOrFetchProgress(companyId, productId, flowId).pipe(
			map((op) => {
				if (routeKey) {
					const routeData = op.onboardingConfiguration!.routeStepData!.get(routeKey)!;
					if (routeData) this.store.dispatch(StepperActions.setActiveRouteData({ routeData, ignoreNextStep: true }));
				}
				return op;
			})
		);
	}

	/*
	 * Fetches the onboard company progress from the server and updates the store.
	 */
	private fetchOnboardCompanyProgress(
		companyId: number,
		productId?: string,
		flowId?: string
	): Observable<OnboardCompanyProgress> {
		const url = `${this.path}/${companyId}`;
		let params = new HttpParams();
		if (productId) {
			params = params.set("productId", productId);
		}
		if (flowId) {
			params = params.set("flowId", flowId);
		}
		return this.http.get<OnboardCompanyProgress>(url, { params }).pipe(
			map((resp: OnboardCompanyProgress) => {
				const op = new OnboardCompanyProgress(
					resp.companyId,
					resp.isOnboardingComplete,
					resp.currentStep,
					resp.currentSubStep,
					resp.onboardingConfiguration,
					resp.productId,
					resp.onboardingConfigurationId
				);
				this.store.dispatch(
					StepperActions.setRouteStepConfiguration({ data: op!.onboardingConfiguration!.routeStepData! })
				);
				this.store.dispatch(CompanyProfileActions.setOnboardCompanyProgress({ progress: op }));

				this.store.dispatch(StepperActions.updateSteps({ steps: op.onboardingConfiguration!.steps }));
				this.store.dispatch(StepperActions.initializeStepper());
				return op;
			})
		);
	}

	createOnboardCompanyProgress(progress: OnboardCompanyProgress): Observable<OnboardCompanyProgress> {
		const url = `${this.path}/${progress.companyId}`;
		return this.http
			.post<OnboardCompanyProgress>(url, progress)
			.pipe(
				map(
					(resp: OnboardCompanyProgress) =>
						new OnboardCompanyProgress(
							resp.companyId,
							resp.isOnboardingComplete,
							resp.currentStep,
							resp.currentSubStep,
							resp.onboardingConfiguration,
							resp.productId,
							resp.onboardingConfigurationId
						)
				)
			);
	}

	updateOnboardCompanyProgress(progress: OnboardCompanyProgress): Observable<OnboardCompanyProgress> {
		const url = `${this.path}/${progress.companyId}`;
		return this.http.put<OnboardCompanyProgress>(url, progress).pipe(map(() => progress));
	}

	private GetOrFetchProgress(
		companyId: number,
		productId?: string,
		flowId?: string
	): Observable<OnboardCompanyProgress> {
		return this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress).pipe(
			take(1),
			mergeMap((prog) => {
				if (!prog || prog.onboardingConfigurationId != flowId) {
					return this.fetchOnboardCompanyProgress(companyId, productId, flowId);
				} else {
					return of(prog);
				}
			})
		);
	}
}
