<div class="grid">
    <div class="g-col-12 g-col-md-6">
        <label class="s">Preferred Payment Method<ls-info-dialog-icon
                (iconClick)="showPaymentInfo()"></ls-info-dialog-icon></label>
        <p class="l-bold">{{ companyProfileRelationship?.preferredPaymentMethodId! | lookup : preferredPayments }}</p>
    </div>
    <div class="g-col-12 g-col-md-6">
        <label class="s">Customer Notification<ls-info-dialog-icon [header]="SharedText.CustomerSettings.notifyHeader"
                [content]="SharedText.CustomerSettings.notifyContent"></ls-info-dialog-icon>
        </label>
        <p class="l-bold">{{ companyProfileRelationship?.financeDisclosureYesNoId! | lookup : financeDisclosures }}</p>
    </div>
    <div class="g-col-12 g-col-md-6">
        <label class="s">Invoice Approvals<ls-info-dialog-icon [header]="SharedText.InvoiceApprovals.tooltipHeader"
                [content]="SharedText.InvoiceApprovals.tooltipContent"></ls-info-dialog-icon>
        </label>
        <p class="l-bold">{{ companyProfileRelationship?.isInstrumentApprovalConfirmed! | booleanDisplay : 'YesNo'}}</p>
    </div>
    <div class="g-col-12 g-col-md-6">
        <label class="s">autoXcel<ls-info-dialog-icon [header]="SharedText.AutoXcel.tooltipHeader"
                [content]="SharedText.AutoXcel.tooltipContent"></ls-info-dialog-icon></label>

        <p class="l-bold">{{ companyProfileRelationship?.isAutoXcel! | booleanDisplay : 'OnOff'}}</p>
    </div>
    <div class="g-col-12 g-col-md-6">
        <label class="s">Expedited Financing<ls-info-dialog-icon [header]="SharedText.ExpeditedFinancing.tooltipHeader"
                [content]="SharedText.ExpeditedFinancing.tooltipContent"></ls-info-dialog-icon>
        </label>

        <p class="l-bold">{{ companyProfileRelationship?.isFinanceExpedited! | booleanDisplay : 'YesNo'}}</p>
    </div>
</div>