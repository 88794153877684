import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs";
import { ConnectFinancialsPersuasionComponent } from "../../../Modals";
import { take } from "rxjs/operators";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { ModalActions } from "../../../../../Core";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { Store } from "@ngrx/store";
import { RouteStepDataService } from "../../../Services";
import { DisplayConfig, HtmlContent, Layout } from "src/app/Models";
import { TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-connect-financials-intro",
	templateUrl: "./connect-financials-intro.component.html",
	styleUrls: ["./connect-financials-intro.component.scss"]
})
export class ConnectFinancialsIntroComponent extends RouteStepDataBaseComponent {
	constructor(
		public store: Store,
		public router: Router,
		private dialog: MdbModalService,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
		this.content = this.activeRouteData?.htmlContent ?? new HtmlContent({ layout: this.DefaultLayout });
	}

	onClick() {
		this.nav();
	}

	openModal() {
		this.dialog
			.open(ConnectFinancialsPersuasionComponent, {
				modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
				ignoreBackdropClick: true
			})
			.onClose.pipe(
				take(1),
				map((action: ModalActions) => {
					if (action === ModalActions.PRIMARY) {
						this.nav();
					} else {
						this.nav(1);
					}
				})
			)
			.subscribe();
	}

	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["h1", new DisplayConfig("Let’s start with some financial details", TextStyle.HEADLINE_LARGE)]
				])
			)
		],
		[
			this.BODY,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"p1",
						new DisplayConfig(
							"Our team needs current financial and customer data to streamline the onboarding and underwriting process, and collection is simple. We can connect directly and securely to your accounting systems and business bank account(s) to expedite this process.",
							TextStyle.BODY_LARGE
						)
					],
					[
						"p2",
						new DisplayConfig(
							"A direct connection allows us to simplify the application process, filling in required information on your behalf to save you the effort of manual entry. If you'd like to learn more about connecting your accounting system and business bank account(s), <a class='ls-hyperlink' href='https://raistone.com/help/connecting-your-accounts' target='_blank'>please see our FAQ page.</a>",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		],
		[
			this.BUTTONS,
			new Layout(
				new Map<string, DisplayConfig>([
					["next", new DisplayConfig("Connect my accounting", TextStyle.BUTTON_LARGE)],
					["skip", new DisplayConfig("I'll do it later", TextStyle.BODY_LARGE_BOLD)]
				])
			)
		]
	]);
}
