import { Injectable } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs";
import { CodatActions } from "./codat-actions";
import { LsHttpErrorResponse } from "src/app/Models";
import { HttpErrorResponse } from "@angular/common/http";
import { CompanyProfileActions } from "src/app/AppStateManagement";
import { CodatService } from "src/services/codat.service";
import { CodatConnectionType } from "src/app/Models/Enums/CodatConnectionType";
import { DisplayedBankAccount } from "src/app/Models/DisplayedBankAccount";

@Injectable()
export class CodatEffects {
	constructor(
		private actions$: Actions,
		private codatService: CodatService,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {}

	fetchCodatIntegrationDataConnections$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CodatActions.getIntegrations),
			switchMap(() =>
				this.codatService
					.getIntegrations()
					.pipe(map((idc: any) => CodatActions.setIntegrations({ integrationDataConnection: idc })))
			)
		)
	);

	createCompanyPlatform$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(CodatActions.createCompanyPlatform),
				mergeMap((codat) =>
					this.codatService.createCodatCompanyPlatform(codat.companyProfile.companyId!, codat.dataConnection).pipe(
						map((url: string) => {
							window.location.href = this.createRedirectUrl(url, CodatConnectionType.ACCOUNTING, codat.redirectPath);
						}),
						catchError(() => {
							return this.codatNavigateOnError(CodatConnectionType.ACCOUNTING);
						})
					)
				)
			),
		{ dispatch: false }
	);

	createConnection$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(CodatActions.createConnection),
				mergeMap((codat) =>
					this.codatService.createCodatConnection(codat.companyProfile).pipe(
						map((url: string) => {
							window.location.href = this.createRedirectUrl(url, CodatConnectionType.BANKING, codat.redirectPath);
						}),
						catchError(() => {
							return this.codatNavigateOnError(CodatConnectionType.BANKING);
						})
					)
				)
			),
		{ dispatch: false }
	);

	createAccountingIntegration$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CodatActions.createCompanyPlatformIntegration),
			mergeMap((act) =>
				this.codatService.saveCodatAccountingConnection(act.companyProfile, act.codatConnection).pipe(
					mergeMap(() => [
						CodatActions.companyPlatformIntegrationSaveSuccessful(),
						CompanyProfileActions.getCompanyProfile({ companyId: act.companyProfile!.companyId! })
					]),
					catchError((error: HttpErrorResponse) => {
						const errResp = new LsHttpErrorResponse(
							error.error.status,
							error.error.title,
							error.error.title,
							error.error.type,
							error.error.errors
						);
						return [CodatActions.companyPlatformIntegrationSaveUnsuccessful({ errors: errResp })];
					})
				)
			)
		)
	);

	createBankingIntegration$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CodatActions.createConnectionIntegration),
			mergeMap((act) =>
				this.codatService.saveCodatBankConnection(act.companyProfile, act.codatConnection).pipe(
					mergeMap(() => [
						CodatActions.connectionIntegrationSaveSuccessful(),
						CompanyProfileActions.getCompanyProfile({ companyId: act.companyProfile!.companyId! }),
						CodatActions.getBankAccounts({ companyId: act.companyProfile!.companyId! })
					]),
					catchError((error: HttpErrorResponse) => {
						const errResp = new LsHttpErrorResponse(
							error.error.status,
							error.error.title,
							error.error.title,
							error.error.type,
							error.error.errors
						);
						return [CodatActions.connectionIntegrationSaveUnsuccessful({ errors: errResp })];
					})
				)
			)
		)
	);

	deleteCompanyPlatform$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CodatActions.deleteCompanyPlatform),
			mergeMap((act) => {
				return this.codatService.deleteCodatCompanyPlatform(act.companyProfile!).pipe(
					switchMap((companyProfile) => {
						return companyProfile.isAccountingPlatformConnected
							? [CompanyProfileActions.setCompanyProfile({ companyProfile: companyProfile! })]
							: [
									CompanyProfileActions.setCompanyProfile({ companyProfile: companyProfile! }),
									CodatActions.deleteCompanyPlatformSuccessful()
								];
					})
				);
			})
		)
	);

	deleteConnection$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CodatActions.deleteConnection),
			mergeMap((act) => {
				return this.codatService.deleteCodatConnection(act.companyProfile!).pipe(
					switchMap((companyProfile) => {
						return companyProfile.isBankingPlatformConnected
							? [CompanyProfileActions.setCompanyProfile({ companyProfile: companyProfile! })]
							: [
									CompanyProfileActions.setCompanyProfile({ companyProfile: companyProfile! }),
									CodatActions.deleteConnectionSuccessful()
								];
					})
				);
			})
		)
	);

	notifyNewAccountingPlatform$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(CodatActions.notifyNewCompanyPlatform),
				filter((act) => !!act.accountingPlatform),
				mergeMap((act) =>
					this.codatService.notifyNewAccountingPlatform(act.accountingPlatform).pipe(
						catchError((error: HttpErrorResponse) => {
							const errResp = new LsHttpErrorResponse(
								error.error.status,
								error.error.title,
								error.error.title,
								error.error.type,
								error.error.errors
							);
							return [CodatActions.connectionIntegrationSaveUnsuccessful({ errors: errResp })];
						})
					)
				)
			),
		{ dispatch: false }
	);

	private createRedirectUrl(url: string, connectType: string, redirectPath: string) {
		const tabIndex = 2;
		const array = url.split("/");
		url += array[array.length - 1].includes("?") ? "&" : "?";
		url += `dynamichost=${window.location.origin}&connecttype=${connectType}
			&path=${redirectPath}&tabIndex=${tabIndex}`;
		return url;
	}

	private codatNavigateOnError(connectType: string) {
		if (window.location.pathname.includes("settings")) {
			const queryParams: Params = { tabIndex: 2, connectType: connectType };
			return [this.router.navigate(["home/settings"], { relativeTo: this.activatedRoute, queryParams: queryParams })];
		} else {
			const errorPath = `onboarding/connect-financials/${connectType.toLowerCase()}-success`;
			return [this.router.navigate([errorPath], { relativeTo: this.activatedRoute })];
		}
	}

	fetchBankAccounts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CodatActions.getBankAccounts),
			switchMap((codat) =>
				this.codatService
					.getBankAccounts(codat.companyId)
					.pipe(
						map((response: DisplayedBankAccount[]) => CodatActions.setBankAccounts({ bankAccounts: response ?? [] }))
					)
			)
		)
	);

	saveAccount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CodatActions.saveBankAccount),
			mergeMap((act) =>
				this.codatService.saveBankAccount(act.companyId, act.codatConnection).pipe(
					mergeMap(() => [
						CodatActions.saveBankAccountSaveSuccessful(),
						CompanyProfileActions.getCompanyProfile({ companyId: act.companyId })
					]),
					catchError((error: HttpErrorResponse) => {
						const errResp = new LsHttpErrorResponse(
							error.error.status,
							error.error.title,
							error.error.title,
							error.error.type,
							error.error.errors
						);
						return [CodatActions.saveBankAccountSaveUnsuccessful({ errors: errResp })];
					})
				)
			)
		)
	);
}
