<div class="d-flex flex-column flex-xl-row">
  <div class="ls-mr-xl-8">
    <ls-text extraClasses="ls-mt-xl-10 ls-mt-l-0" [displayConfig]="config(HEADER, 'hl1')"></ls-text>
    <ls-text extraClasses="ls-mt-3 ls-mb-8" [displayConfig]="config(HEADER, 'p1')"></ls-text>
  </div>
  <img class="image-container" src="../../../../../assets/SuccessGraphic.png" />

</div>
<button type="button" class="btn btn-outline-tertiary btn-lg ls-mt-11" (click)="onClick()">
  <ls-text [displayConfig]="config(BUTTONS,'next')"></ls-text>
</button>