import { inject, Injectable } from "@angular/core";
import { CanActivateFn, UrlTree } from "@angular/router";
import { LsRole, UserRoleGuardService } from "@limestone/ls-shared-modules";
import { Store } from "@ngrx/store";
import { map, Observable } from "rxjs";
import { AppSelectors } from "../AppStateManagement";

@Injectable()
export class UserRoleGuard {
	constructor(
		private store: Store,
		private appSelectors: AppSelectors,
		private userRoleGuardService: UserRoleGuardService
	) {}
	canActivate(roles: LsRole[]): Observable<boolean | UrlTree> {
		const userRoles$ = this.store
			.select(this.appSelectors.selectUserRoles)
			.pipe(map((userRoles) => userRoles?.roles.map((ur) => ur.userRole.Id)));

		return this.userRoleGuardService.canActivate(roles, userRoles$, "/home");
	}
}

export const canActivateForRoles = (roles: LsRole[]): CanActivateFn => {
	return () => inject(UserRoleGuard).canActivate(roles);
};
