import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subject, filter, map, takeUntil, combineLatest } from "rxjs";
import { CompanyProfileRelationship, DisplayConfig, Layout } from "src/app/Models";
import { CompanyProfileRelationshipSelectors, CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { OnboardApplicationStatus } from "@limestone/ls-shared-modules";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../Services";
import { ContinueApplicationService } from "../../../../../../services";
import { TextStyle } from "../../../../../Models/Enums";

@Component({
	selector: "ls-review",
	templateUrl: "./review.component.html",
	styleUrls: ["./review.component.scss"]
})
export class ReviewComponent extends RouteStepDataBaseComponent implements OnInit, OnDestroy {
	protected componentTeardown$ = new Subject();
	companyProfileRelationships?: CompanyProfileRelationship[];
	hasEnrolledCustomers = true;
	private isAccountingConnected?: boolean;

	constructor(
		public store: Store,
		public router: Router,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService,
		private continueApplicationService: ContinueApplicationService
	) {
		super(routeStepDataService);
		this.setDefaultLayout(this.DefaultLayout);
	}

	ngOnInit() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationships)
		])
			.pipe(
				filter(([cp, cprs]) => !!cp && !!cprs),
				takeUntil(this.componentTeardown$),
				map(([cp, cprs]) => {
					this.isAccountingConnected = cp?.isAccountingPlatformConnected;
					this.companyProfileRelationships = cprs?.filter(
						(cpr) => cpr.applicationStatusId === OnboardApplicationStatus.INPROGRESS
					);
					this.hasEnrolledCustomers =
						this.companyProfileRelationships?.find((cpr) => cpr.isCustomerSetupComplete) !== undefined;
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	navToCustomerContact(customer: CompanyProfileRelationship) {
		if (customer.isCustomerSetupComplete) {
			this.nav(3, new Map<string, string | number>([["relationshipCompanyId", customer.relationshipCompanyId!]]));
		} else {
			this.continueApplicationService.continueCustomerSetup(customer.relationshipCompanyId!, customer.currentSubStep!);
		}
	}

	addAnotherCustomer() {
		const index = this.isAccountingConnected ? 2 : 1;
		this.nav(index);
	}

	continue() {
		this.nav();
	}

	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig("Here are the customers you’ve set up so far", TextStyle.HEADLINE_LARGE)],
					[
						"p1",
						new DisplayConfig(
							"You can edit your customer info, add a new one to your Accounts Receivable Finance application, or continue to finalize the application.",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		],
		[
			this.BODY,
			new Layout(
				new Map<string, DisplayConfig>([
					["cpr-b1", new DisplayConfig("Continue", TextStyle.BUTTON_LARGE)],
					["b1", new DisplayConfig("Add another customer", TextStyle.BUTTON_LARGE)],
					["b2", new DisplayConfig("Continue application", TextStyle.BUTTON_LARGE)]
				])
			)
		]
	]);
}
