<div class="root-container d-flex flex-fill">
    <div class="ls-nav-menu ls-pt-6 ls-pb-6 d-flex flex-column" [ngClass]="{'expanded': expanded}">
        <div class="ls-ml-sm-4 ls-mb-sm-16 d-flex justify-content-between">
            <img class="raistone-logo" src="../../../../../assets/Raistone%5Bwhite%5D.svg" alt="raistone-white" />
            <span class="material-icons justify-content-end align-self-center d-sm-none"
                (click)="toggleExpand()">{{expanded ? 'close' : 'menu'}}</span>
        </div>
        <div [ngClass]="{'d-none': !expanded}"
            class="d-none d-sm-flex flex-sm-fill flex-column justify-content-between">
            <div class="ls-nav d-flex flex-column ls-ml-sm-4">
                <ng-container *ngFor="let nav of navItems; let i = index">
                    <div class="ls-nav-option ls-mb-7 d-flex justify-content-between hoverable"
                        *ngIf="checkRoles(nav.allowedRoles)" (click)="setActiveIndex(i)"
                        [ngClass]="{'active': i === activeIndex }" [routerLink]="nav.route">
                        <label class="l ls-nav-item align-self-center" [routerLink]="nav.route">
                            <span class="material-icons"
                                [ngClass]="{'active': i === activeIndex }">{{nav.icon}}</span>{{nav.label}}
                        </label>
                        <label class="ls-nav-item align-self-center" [routerLink]="nav.route">
                            <span *ngIf="nav.notifications !== undefined && nav.notifications > 0"
                                class="ls-badge-notification-error l">{{nav.notifications}}</span>
                        </label>
                    </div>
                </ng-container>
            </div>


        </div>
        <div class="align-items-bottom">
            <div class="ls-nav-option ls-ml-sm-4 d-flex justify-content-between"
                [ngClass]="{'active': navItems.length === activeIndex }" (click)="setActiveIndex(navItems.length)">
                <label class="l ls-nav-item align-self-center" [routerLink]="'./settings'">
                    <span class="material-icons ls-mr-3"
                        [ngClass]="{'active': navItems.length === activeIndex }">settings</span>Settings
                </label>
                <label *ngIf="isOnboardingV103Enabled" class="l ls-nav-item align-self-center" (click)="logOut()">
                    <span class="material-icons md-18 ls-mr-2">logout</span>
                </label>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column flex-fill ls-content-container" [ngClass]="{'d-none': expanded}">
        <div class="d-flex flex-column ls-content-spacing ls-mb-11">
            <router-outlet></router-outlet>
        </div>
        <ls-footer class="footer d-flex flex-column-reverse flex-grow-1"></ls-footer>
    </div>
</div>