<div class="grid ls-mt-8" style="--bs-gap:3.5rem;">
    <div class="g-col-12">
        <label class="xs-bold">Invoice Source</label>
        <p class="l-bold">{{ companyProfileRelationship?.instrumentPlatform?.name }}</p>
    </div>
    <div class="g-col-12">
        <label class="xs-bold">Invoice Approval Status Source
            <ls-info-dialog-icon [header]="SharedText.InvoiceApprovals.tooltipHeader"
                [content]="SharedText.InvoiceApprovals.tooltipContent"></ls-info-dialog-icon>
        </label>
        <p class="l-bold">{{ companyProfileRelationship?.instrumentApprovalPlatform?.name }}</p>
    </div>
    <div class="g-col-12">
        <label class="xs-bold">Remittance Data Source
            <ls-info-dialog-icon content="Remittance history refers to the transaction history between
                    you and your customer. We use it to confirm that your customer has paid you"></ls-info-dialog-icon>
        </label>
        <p class="l-bold">{{ companyProfileRelationship?.remittancePlatform?.name }}</p>
    </div>
</div>