<div class="modal-header ls-mt-0 ls-mr-5 ls-ml-5 ls-p-0 border-0">
    <ls-text extraClasses="ls-mt-5 ls-mb-3" [displayConfig]="headerConfig"></ls-text>
    <button class="btn-close ls-mt-0 ls-pt-0" (click)="modalRef.close()"></button>
</div>
<div class="modal-body ls-mt-0 ls-mr-5 ls-ml-5 ls-mb-5 ls-p-0">
    <ls-text [displayConfig]="contentConfig"></ls-text>
    <table class="table">
        <thead class="text-nowrap bg-white s">
            <tr>
                <th>Payment Method</th>
                <th>Speed</th>
                <th>Fees</th>
            </tr>
        </thead>
        <tbody class="m">
            <tr>
                <td>ACH</td>
                <td>4 weeks, requires passthrough payment</td>
                <td>No fee</td>
            </tr>
            <tr>
                <td>Virtual Card</td>
                <td>Immediate</td>
                <td>Swipe fee, but may eligible for cash back rewards*</td>
            </tr>
        </tbody>
    </table>
</div>