import { Component, ViewChild } from "@angular/core";
import { CompanyProfileRelationship, DisplayConfig, Layout } from "src/app/Models";
import { PreferredPaymentMethod, TextStyle } from "src/app/Models/Enums";
import { Store } from "@ngrx/store";
import { filter, map, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import copy from "fast-copy";
import { FormGroup, Validators } from "@angular/forms";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { Actions } from "@ngrx/effects";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { RouteStepDataService } from "../../../Services";
import { SharedText } from "../shared-text";
import { InfoDialogService } from "src/services";
import { PreferredPaymentInfoComponent } from "./preferred-payment-info-dialog";
import { RadioButtonTooltip } from "src/app/Models/RadioButtonTooltip";

@Component({
	selector: "ls-preferred-payment",
	templateUrl: "./preferred-payment.component.html",
	styleUrls: ["./preferred-payment.component.scss"]
})
export class PreferredPaymentComponent extends CustomerRelationshipBaseComponent {
	PREFERRED_PAYMENT_METHOD = "preferredPaymentMethod";
	ppmForm: FormGroup = new FormGroup({});

	nextButtonText = "Complete Customer Setup";

	public companyProfileRelationship?: CompanyProfileRelationship;
	public preferredPaymentMethods?: GenericLookup<string>[];

	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;

	constructor(
		public actions$: Actions,
		public store: Store<any>,
		public router: Router,
		public genericSelectors: GenericSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService,
		public dialogService: InfoDialogService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.setDefaultLayout(this.DefaultLayout);
		this.initData();
	}

	initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
				})
			)
			.subscribe();

		this.store
			.select(this.genericSelectors.selectLookup(LookupTables.PreferredPaymentMethod))
			.pipe(
				filter((ppm) => !!ppm && ppm.length > 0),
				takeUntil(this.componentTeardown$),
				map((ppm) => {
					this.preferredPaymentMethods = ppm!;
				})
			)
			.subscribe();
		this.setupControl(
			this.ppmForm,
			this.PREFERRED_PAYMENT_METHOD,
			this.companyProfileRelationship?.preferredPaymentMethodId,
			Validators.required
		);
	}
	showPaymentInfo() {
		this.dialogService.showDialog(PreferredPaymentInfoComponent);
	}
	continue() {
		if (this.ppmForm.dirty) {
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.preferredPaymentMethodId = this.ppmForm.get(
				this.PREFERRED_PAYMENT_METHOD
			)?.value;
			updatedCompanyProfileRelationship.isCustomerSetupComplete = true;
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hm1", new DisplayConfig("How do you prefer to get paid?", TextStyle.HEADLINE_MEDIUM)]
				])
			)
		],
		[
			this.BODY,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"p2",
						new DisplayConfig(
							"Different methods of payment affect the speed your application can be processed, any fees, and other factors.",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		],
		[
			this.PREFERRED_PAYMENT_METHOD,
			new Layout(
				undefined,
				new Map<string | number, RadioButtonTooltip>([[PreferredPaymentMethod.CARD, SharedText.VirtualCard.tooltip]])
			)
		]
	]);
}
