import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SecurityContext,
	ViewChild
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Subject, filter, map, takeUntil } from "rxjs";
import { FileHandle } from "src/Elements/upload-widget/FileHandle";
import { FileUploadService } from "src/Elements/upload-widget/file-upload.service";
import { AppState, SalesforceSupportActions } from "src/app/AppStateManagement";
import { EmailMessage } from "src/app/Models";
import { Attachment } from "src/app/Models/Attachment";
import { IAlert } from "src/app/Models/Interfaces";
import { environment } from "src/environments/environment";

@Component({
	selector: "ls-case-message-reply",
	templateUrl: "./case-message-reply.component.html",
	styleUrls: ["./case-message-reply.component.scss"]
})
export class CaseMessageReplyComponent implements OnInit, OnDestroy {
	@ViewChild("upload") uploadInput!: ElementRef;
	@Input() public fromAddress!: string;
	@Input() public parentId!: string;
	@Input() public threadId!: string;
	@Input() public replyToId!: string;
	@Input() public userId!: number;
	@Input() public caseId!: number;
	@Output() public closeReply: EventEmitter<null> = new EventEmitter();

	private _componentTeardown$: Subject<any> = new Subject<any>();
	private _delayTime = 4000;
	public sendDisabled = true;
	public errorMessage?: IAlert;
	public RESPONSE = "response";
	public attachments: Attachment[] = [];
	public maxFileCount = 10;
	public maxFileSize = 3;
	public maxTotalFileSize = 25;
	public emailCharacterLimit = 10000;
	public form: FormGroup = new FormGroup({
		response: new FormControl(null)
	});

	constructor(
		private readonly _fileUploadService: FileUploadService,
		private readonly _sanitizer: DomSanitizer,
		private readonly _actions$: Actions,
		private readonly _store: Store<AppState>
	) {}

	public ngOnInit(): void {
		this._actions$
			.pipe(
				takeUntil(this._componentTeardown$),
				ofType(SalesforceSupportActions.emailMessageSendSuccessful),
				map(() => this.close())
			)
			.subscribe();

		this._actions$
			.pipe(
				takeUntil(this._componentTeardown$),
				ofType(SalesforceSupportActions.emailMessageSendUnsuccessful),
				map(() => {
					this._setErrorMessage("Failed to send message.");
					this._toggleSendDisabled();
				})
			)
			.subscribe();

		this.form
			.get(this.RESPONSE)
			?.valueChanges.pipe(
				filter((val) => val !== null),
				map((value) => (this.sendDisabled = value.length < 1))
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this._componentTeardown$.next(null);
		this._componentTeardown$.complete();
	}

	public openFileInput(event: Event): void {
		event.stopPropagation();
		this.uploadInput.nativeElement.click();
	}

	public handleFiles(ev: EventTarget | null): void {
		const files = (ev as HTMLInputElement).files ?? new FileList();
		const fileHandles = this._fileUploadService.convert(files);

		if (files.length > 0) {
			if (this.attachments.length + files.length > this.maxFileCount) {
				this._setErrorMessage(
					`You’ve reached the maximum amount of files. Please consolidate them to a maximum of ${this.maxFileCount} files.`
				);
				return;
			}

			fileHandles.map((fileHandle: FileHandle) => {
				if (this._isFileSizeOverLimit(fileHandle.file.size, this.maxFileSize)) {
					this._setErrorMessage(
						`${fileHandle.file.name} exceeds the maximum file size. Please upload documents smaller than ${this.maxFileSize} MB`
					);
					return;
				}

				this.attachments?.push({
					body: fileHandle,
					bodyLength: fileHandle.file.size,
					name: fileHandle.file.name,
					url: fileHandle.url
				});
			});
		}
	}

	public removeAttachment(index: number): void {
		this.attachments.splice(index, 1);
		this.uploadInput.nativeElement.value = "";
	}

	public openAttachment(index: number): void {
		const attachment = this.attachments[index];
		const url = this._sanitizer.sanitize(SecurityContext.URL, attachment.url!);
		window.open(url!, "_blank");
	}

	public sendMessage(): void {
		if (this._totalFileSizeOverLimit()) {
			return;
		}
		this._toggleSendDisabled();
		const message = this.form.get(this.RESPONSE)?.value;
		const hasAttachments = this.attachments.length > 0;
		const email = new EmailMessage(
			undefined,
			this.fromAddress,
			environment.supportEmailAddress,
			this.parentId,
			hasAttachments,
			undefined,
			undefined,
			message,
			this.attachments,
			this.threadId,
			this.replyToId
		);
		const files = this.attachments.map((f) => f.body!.file);
		this._store.dispatch(
			SalesforceSupportActions.sendEmailMessage({
				emailMessage: email,
				files: files,
				userId: this.userId,
				caseId: this.caseId!
			})
		);
	}

	public close(): void {
		this.form.reset();
		this.attachments = [];
		this.sendDisabled = true;
		this.closeReply.emit();
	}

	private _isFileSizeOverLimit(size: number, maxSize: number): boolean {
		const maxBytes = this._convertMegaBytesToBytes(maxSize);
		return size >= maxBytes;
	}

	private _totalFileSizeOverLimit(): boolean {
		let isOverLimit = false;
		const totalFileSize = this.attachments.reduce((accumulator, attachment) => accumulator + attachment.bodyLength!, 0);

		if (this._isFileSizeOverLimit(totalFileSize, this.maxTotalFileSize)) {
			isOverLimit = true;
			this._setErrorMessage(`Please upload documents that have a total size less than ${this.maxTotalFileSize} MB.`);
		}

		return isOverLimit;
	}

	private _convertMegaBytesToBytes(megabytes: number): number {
		return megabytes * 1048576;
	}

	private _resetErrorMessage(): void {
		this.errorMessage = undefined;
	}

	private _setErrorMessage(message: string): void {
		this.errorMessage = {
			type: "danger",
			message: message,
			icon: "error"
		};
		this.uploadInput.nativeElement.value = null;
		setTimeout(() => this._resetErrorMessage(), this._delayTime);
	}

	private _toggleSendDisabled(): void {
		this.sendDisabled = !this.sendDisabled;
	}
}
