<div>
    <form [formGroup]="radioButtonForm">
        <div *ngFor="let lookup of lookups;let isLast = last" [ngClass]="{'ls-mb-3': !isLast}"
            [class]="'d-flex align-items-center '+extraClasses">
            <input *ngIf="!!controlName" mdbRadio [formControlName]="controlName!" class="radio" type="radio"
                [id]="controlName+'_'+lookup.id" [value]="lookup.id" />
            <label class="xl" [for]="controlName+'_'+lookup.id">
                {{ lookup.name }}
                <ls-info-dialog-icon *ngIf="!!getTooltip(lookup!.id)" [header]="getTooltip(lookup!.id)?.header"
                    [content]="getTooltip(lookup!.id)?.content"></ls-info-dialog-icon>
            </label>
        </div>
    </form>
</div>