import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { RouteStepDataBaseComponent } from "../../../../Core/route-step-data-base.component";
import { RouteStepDataService } from "../../Services";
import { DisplayConfig, Layout } from "src/app/Models";
import { TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-successful-application",
	templateUrl: "./successful-application.component.html",
	styleUrls: ["./successful-application.component.scss"]
})
export class SuccessfulApplicationComponent extends RouteStepDataBaseComponent {
	constructor(
		public router: Router,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
		this.setDefaultLayout(this.DefaultLayout);
	}

	onClick() {
		this.nav();
	}

	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig("You did it!", TextStyle.HEADLINE_LARGE)],
					[
						"p1",
						new DisplayConfig(
							"Thank you for submitting an application. A member of the Raistone team will reach out with any follow-up questions and final documentation. You are on your way to getting paid on your terms.",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		],
		[
			this.BUTTONS,
			new Layout(
				new Map<string, DisplayConfig>([["next", new DisplayConfig("Continue to portal", TextStyle.BUTTON_LARGE)]])
			)
		]
	]);
}
