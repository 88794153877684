<div class="d-flex flex-column">
  	<div *ngIf="display(HEADER)">
		<ls-text extraClasses="ls-mb-4" [displayConfig]="config(HEADER, 'hl1')"></ls-text>
		<ls-text extraClasses="ls-mb-6" [displayConfig]="config(HEADER, 'p1')"></ls-text>
	</div>
	<div *ngIf="display(BUTTONS)">
		<button type="button" class="btn btn-lg btn-primary ls-mb-6" (click)="onClick()">
			<ls-text [displayConfig]="config(BUTTONS, 'b1')"></ls-text>
		</button>
	</div>
	<div *ngIf="display(FOOTER)">
		<ls-text [displayConfig]="config(FOOTER, 'p1')"></ls-text>
	</div>
</div>