import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { filter, map, takeUntil } from "rxjs";
import { Address, CompanyProfile, CompanyProfileRelationship, DisplayConfig, Layout } from "src/app/Models";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { OnboardApplicationStatus } from "@limestone/ls-shared-modules";
import { RouteStepDataService } from "../../../Services";
import { TextStyle } from "../../../../../Models/Enums";

@Component({
	selector: "ls-customer-contact",
	templateUrl: "./customer-contact.component.html",
	styleUrls: ["./customer-contact.component.scss"]
})
export class CustomerContactComponent extends CustomerRelationshipBaseComponent implements OnInit {
	constructor(
		public actions$: Actions,
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.setDefaultLayout(this.DefaultLayout);
		this.initData();
	}

	LEGAL_NAME = "legalName";
	BILLING = "billing";

	public customerContactForm: FormGroup = new FormGroup({});
	public companyProfile?: CompanyProfile;
	public companyProfileRelationship?: CompanyProfileRelationship;
	private isCodatCustomer?: boolean;
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;

	ngOnInit() {
		const params = this.activatedRoute.snapshot.queryParams;
		this.isCodatCustomer = params["isCodatCustomer"];
	}

	initData() {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					this.setFormGroup();
				})
			)
			.subscribe();
	}

	setFormGroup() {
		const address = this.companyProfileRelationship?.billingAddress ?? null;

		this.customerContactForm = new FormGroup({
			legalName: new FormControl(this.companyProfileRelationship?.relationshipCompanyName, Validators.required),
			billing: new FormControl(address, Validators.required)
		});
	}

	continue() {
		if (this.customerContactForm.dirty) {
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			const addressForm: Address = this.customerContactForm.get(this.BILLING)?.value;
			const applicationStatusId =
				updatedCompanyProfileRelationship.applicationStatusId! in OnboardApplicationStatus
					? updatedCompanyProfileRelationship.applicationStatusId
					: OnboardApplicationStatus.INPROGRESS;
			updatedCompanyProfileRelationship.relationshipCompanyName = this.customerContactForm.get(this.LEGAL_NAME)?.value;
			updatedCompanyProfileRelationship.billingAddress = addressForm;
			updatedCompanyProfileRelationship.billingAddress.id = this.companyProfileRelationship?.billingAddress?.id;
			updatedCompanyProfileRelationship.applicationStatusId = applicationStatusId;

			if (this.companyProfileRelationship?.relationshipCompanyId) {
				this.store.dispatch(
					CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
						companyProfileRelationship: updatedCompanyProfileRelationship,
						isEnrolledCodatCustomer: this.isCodatCustomer ?? false
					})
				);
			} else {
				updatedCompanyProfileRelationship.isManualSetup = true;
				this.store.dispatch(
					CompanyProfileRelationshipActions.createCompanyProfileRelationship({
						companyProfileRelationship: updatedCompanyProfileRelationship
					})
				);
			}
		} else {
			if (this.isCodatCustomer) {
				this.store.dispatch(
					CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
						companyProfileRelationship: this.companyProfileRelationship!,
						isEnrolledCodatCustomer: this.isCodatCustomer
					})
				);
			}
			const index = this.companyProfileRelationship?.isManualSetup ? 0 : 1;
			this.navigateToNextScreen(index);
		}
	}

	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hs1", new DisplayConfig("Confirm your customer’s information", TextStyle.HEADLINE_SMALL)]
				])
			)
		],
		[
			this.BODY,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"p1",
						new DisplayConfig(
							"In the form below, please provide information for the customer whose receivables you would like to finance. Raistone will not contact your customers without your prior approval.",
							TextStyle.BODY_LARGE
						)
					],
					["q1-l1", new DisplayConfig("Legal Business Name", TextStyle.LABEL_MEDIUM)],
					["q2-l1", new DisplayConfig("Customer Billing Address", TextStyle.LABEL_SMALL_CAPS)]
				])
			)
		]
	]);
}
