import { Component } from "@angular/core";
import { DisplayConfig, Layout } from "../../Models";
import { TextStyle } from "../../Models/Enums";
import { MsalService } from "@azure/msal-angular";
import { Router } from "@angular/router";

@Component({
	selector: "ls-not-found",
	templateUrl: "./not-found.component.html",
	styleUrls: ["./not-found.component.scss"]
})
export class NotFoundComponent {
	constructor(
		private msalService: MsalService,
		private router: Router
	) {}

	onClick() {
		const activeAccount = this.msalService?.instance?.getActiveAccount();
		if (activeAccount != null) {
			this.router.navigate(["home"]);
		} else {
			this.navToRoot();
		}
	}

	navToRoot() {
		window.location.href = window.location.origin;
	}

	DefaultLayout = new Map<string, Layout>([
		[
			"HEADER",
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig("Page not found", TextStyle.HEADLINE_LARGE)],
					[
						"hs1",
						new DisplayConfig(
							"The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.",
							TextStyle.HEADLINE_SMALL
						)
					]
				])
			)
		],
		[
			"BUTTON",
			new Layout(new Map<string, DisplayConfig>([["b1", new DisplayConfig("Raistone Portal", TextStyle.BUTTON_LARGE)]]))
		]
	]);
}
