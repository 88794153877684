<div class="d-flex flex-column">
	<div *ngIf="display(HEADER)" class="l ls-mb-8">
	  <ls-text id='h1' [displayConfig]="config(HEADER, 'h1')"></ls-text>
	</div>
	<div class="ls-mb-11">
	  <ls-text id='p1' extraClasses="ls-mb-3" [displayConfig]="config(BODY, 'p1')"></ls-text>
	  <ls-text id='p2' extraClasses="ls-mb-0" [displayConfig]="config(BODY, 'p2')"></ls-text>
	</div>
	<div class="d-flex flex-sm-row flex-column justify-content-between">
		<button type="button" (click)="onClick()" class="btn btn-primary btn-lg ls-mb-3 ls-mb-md-0">
			<ls-text id='next' [displayConfig]="config(BUTTONS, 'next')"></ls-text>
		</button>
		<button type="button" (click)="openModal()" class="btn btn-lg btn-primary-borderless">
			<ls-text id='skip' [displayConfig]="config(BUTTONS, 'skip')"></ls-text>
		</button>
	</div>
</div>