import { Component } from "@angular/core";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { DisplayConfig } from "src/app/Models";
import { TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-preferred-payment-info-dialog",
	templateUrl: "./preferred-payment-info-dialog.html",
	styleUrls: ["./preferred-payment-info-dialog.scss"]
})
export class PreferredPaymentInfoComponent {
	constructor(public modalRef: MdbModalRef<any>) {}
	get headerConfig() {
		return new DisplayConfig("Payment methods", TextStyle.TITLE_LARGE);
	}
	get contentConfig() {
		return new DisplayConfig(
			"Different methods of payment affect the speed your application can be processed, any fees, and other factors.",
			TextStyle.BODY_LARGE
		);
	}
}
