import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { CompanyProfileActions, CompanyProfileSelectors } from "src/app/AppStateManagement";
import {
	AsyncValidators,
	Country,
	CountryCode,
	CurrencyCode,
	GenericLookup,
	GenericSelectors,
	LookupTables,
	LsValidators
} from "@limestone/ls-shared-modules";
import { RouteStepDataService } from "../../../Services";
import { BankAccount, BankAccountPaymentMethods, BankAccountValidation, CompanyProfile } from "src/app/Models";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { combineLatest, debounce, filter, interval, map, takeUntil } from "rxjs";
import { AddressFormControlComponent } from "../..";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import copy from "fast-copy";
import { BankAccountType, PaymentMethod } from "src/app/Models/Enums";
import { ValidationActions } from "../../../OnboardingStateManagement";
import { Actions, ofType } from "@ngrx/effects";

@Component({
	selector: "ls-manual-bank-account",
	templateUrl: "./manual-bank-account.component.html",
	styleUrls: ["./manual-bank-account.component.scss"]
})
export class ManualBankAccountComponent extends RouteStepDataBaseComponent implements OnInit, AfterViewInit {
	@ViewChild(AddressFormControlComponent) public addressComponent?: AddressFormControlComponent;
	public form: FormGroup = new FormGroup({});
	public isUsBased = false;
	public BANK_ADDRESS = "BANK_ADDRESS";
	public BANK_NAME = "BANK_NAME";
	public BANK_ALIAS = "BANK_ALIAS";
	public COUNTRY = "country";
	public ABA_ROUTING = "ABA_ROUTING";
	public ACCOUNT_NUMBER = "ACCOUNT_NUMBER";
	public INTERMEDIARY_CORRESPONDENT_BANK_NAME = "INTERMEDIARY_CORRESPONDENT_BANK_NAME";
	public SWIFT_CODE = "SWIFT_CODE";
	public IBAN_ACCOUNT_NUMBER = "IBAN_ACCOUNT_NUMBER";
	public BANK_ACCOUNT_LABEL = "Your company's bank account";
	public companyProfile?: CompanyProfile;
	public bankAccountType?: GenericLookup<string>;
	public currency?: GenericLookup<string>;
	public paymentMethod?: GenericLookup<string>;
	public CountryCode = CountryCode;
	public usBasedControlNames: string[] = [this.ABA_ROUTING, this.ACCOUNT_NUMBER];
	public nonUsBasedControlNames: string[] = [
		this.INTERMEDIARY_CORRESPONDENT_BANK_NAME,
		this.SWIFT_CODE,
		this.IBAN_ACCOUNT_NUMBER
	];

	public allowedKeys: string[] = [
		"Backspace",
		"Delete",
		"Control",
		"ArrowRight",
		"ArrowLeft",
		"ArrowUp",
		"ArrowDown",
		"v",
		" "
	];

	constructor(
		public store: Store<any>,
		private companyProfileSelectors: CompanyProfileSelectors,
		private genericSelectors: GenericSelectors,
		public routeStepDataService: RouteStepDataService,
		private actions$: Actions
	) {
		super(routeStepDataService);
	}

	public ngOnInit(): void {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.PaymentMethod)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.BankAccountType)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.Currency))
		])
			.pipe(
				filter(([cp, pm, bt, cc]) => !!cp && !!pm && !!bt && !!cc),
				takeUntil(this.componentTeardown$),
				map(([cp, pm, bt, cc]) => {
					this.bankAccountType = bt!.find((type) => type.id === BankAccountType.CUSTOMER_RECEIVING);
					this.currency = cc!.find((currency) => currency.id === CurrencyCode.USD);
					this.paymentMethod = pm!.find((m) => m.id === PaymentMethod.LOW_COST);
					this.companyProfile = cp;
					this.isUsBased = cp!.bankAccount?.country.code === CountryCode.US;
					this.setFormControls(cp!.bankAccount);
				})
			)
			.subscribe();
	}

	public ngAfterViewInit(): void {
		this.addressComponent?.formGroup
			.get(this.COUNTRY)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((country: Country) => {
					if (country !== undefined && country.code !== undefined) {
						this.toggleBankControls(country);
					}
				})
			)
			.subscribe();
	}

	public validateIban() {
		this.form
			.get(this.IBAN_ACCOUNT_NUMBER)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				debounce(() => interval(500)),
				map((v) => {
					if (v != undefined && v.length > 0) {
						const validation = new BankAccountValidation(this.getBankAccValueFromForm());
						this.store.dispatch(ValidationActions.validateBankAcc({ bankAccount: validation }));
					}
				})
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(ValidationActions.validationFailure),
				filter((act) => !!act.result),
				map((action) => {
					const errorMap = action.result!.errors;
					switch (action.validationType) {
						case "BankAccount": {
							const errors = errorMap.get("BankAccount.Iban");
							if (errors) {
								const str = errors.join("<br/>");
								this.form.get(this.IBAN_ACCOUNT_NUMBER)?.setErrors({ error: str });
							}
							break;
						}
						default:
							throw new Error("Unknown validation type.");
					}
				})
			)
			.subscribe();
	}

	getControlError(controlName: string): string {
		return this.form.get(controlName)?.errors!["error"];
	}

	public setFormControls(existingBankAccount?: BankAccount): void {
		this.form = new FormGroup({
			BANK_NAME: new FormControl(existingBankAccount?.bankName, Validators.required),
			BANK_ALIAS: new FormControl(existingBankAccount?.alias, Validators.required),
			BANK_ADDRESS: new FormControl(existingBankAccount, Validators.required),
			ABA_ROUTING: new FormControl(null, [Validators.required], [AsyncValidators.lengthAsync(9, 9)]),
			ACCOUNT_NUMBER: new FormControl(null, [Validators.required], [AsyncValidators.lengthAsync(5, 17)]),
			INTERMEDIARY_CORRESPONDENT_BANK_NAME: new FormControl(
				existingBankAccount?.intermediaryCorrespondentBankName,
				Validators.required
			),
			SWIFT_CODE: new FormControl(null, [Validators.required], [AsyncValidators.lengthAsync(8, 11)]),
			IBAN_ACCOUNT_NUMBER: new FormControl(null, [Validators.required], [AsyncValidators.lengthAsync(20, 41)])
		});
		this.toggleBankControls(existingBankAccount?.country);
		if (existingBankAccount) {
			this.form.get(this.IBAN_ACCOUNT_NUMBER)?.removeValidators(Validators.required);
			this.form.get(this.SWIFT_CODE)?.removeValidators(Validators.required);
			this.form.get(this.ACCOUNT_NUMBER)?.removeValidators(Validators.required);
			this.form.get(this.ABA_ROUTING)?.removeValidators(Validators.required);
		}
		this.validateIban();
	}

	private toggleBankControls(country?: Country) {
		this.checkIfUSBased(country);
		if (this.isUsBased) {
			this.updateControls(this.usBasedControlNames, true);
			this.updateControls(this.nonUsBasedControlNames, false);
		} else {
			this.updateControls(this.nonUsBasedControlNames, true);
			this.updateControls(this.usBasedControlNames, false);
		}
	}

	public onClick(): void {
		if (this.form.dirty) {
			const bankAccount = this.getBankAccValueFromForm();
			const updatedCompanyProfile: CompanyProfile = copy(this.companyProfile!);
			updatedCompanyProfile.bankAccount = bankAccount;
			updatedCompanyProfile.hasUSBankAccount = this.isUsBased;
			this.store.dispatch(CompanyProfileActions.setCompanyProfile({ companyProfile: updatedCompanyProfile }));
			this.store.dispatch(CompanyProfileActions.updateCompanyProfile({ companyProfile: updatedCompanyProfile }));
		}

		this.nav();
	}

	private getBankAccValueFromForm(): BankAccount {
		const formValue = this.form.value;
		const bankAddress = formValue[this.BANK_ADDRESS];
		this.checkIfUSBased(bankAddress.country);
		return new BankAccount(
			bankAddress.address1,
			bankAddress.address2,
			formValue[this.BANK_ALIAS],
			this.bankAccountType!,
			formValue[this.BANK_NAME],
			bankAddress.city,
			this.companyProfile!.companyId!,
			bankAddress.country,
			this.currency!,
			[new BankAccountPaymentMethods(this.paymentMethod!)],
			bankAddress.postalCode,
			bankAddress.state,
			this.companyProfile?.bankAccount?.id,
			true,
			undefined,
			formValue[this.INTERMEDIARY_CORRESPONDENT_BANK_NAME],
			formValue[this.ABA_ROUTING] ?? undefined,
			formValue[this.ACCOUNT_NUMBER] ?? undefined,
			formValue[this.SWIFT_CODE] ?? undefined,
			formValue[this.IBAN_ACCOUNT_NUMBER] ? formValue[this.IBAN_ACCOUNT_NUMBER].replace(/\s/g, "") : undefined
		);
	}

	public checkIfUSBased(country?: Country): void {
		const unitedStateCountryCodes: string[] = [
			CountryCode.AS,
			CountryCode.GU,
			CountryCode.MP,
			CountryCode.PR,
			CountryCode.UM,
			CountryCode.US,
			CountryCode.VI
		];
		if (country !== undefined && country.code !== undefined) {
			this.isUsBased = unitedStateCountryCodes.includes(country.code);
		}
	}

	public onKeyboardEvent(event: KeyboardEvent): void {
		if (!LsValidators.allowedInput(event, /([\w]|[0-9]| )+/g, this.allowedKeys)) {
			if (event.key !== "Delete" && event.key !== "Backspace") {
				event.preventDefault();
			}
		}
	}

	public onClipboardEvent(event: ClipboardEvent): void {
		const isValid = LsValidators.allowedInput(event, /^([\w]|[0-9]| )+$/g, this.allowedKeys);
		if (!isValid) {
			event.preventDefault();
		}
	}

	private updateControls(controlNames: string[], addValidators: boolean) {
		for (const name of controlNames) {
			const formControl = this.form.get(name)!;

			if (addValidators) {
				formControl.addValidators(Validators.required);
			} else {
				formControl.removeValidators(Validators.required);
			}

			formControl?.updateValueAndValidity();
		}
	}

	controlHasError(controlName: string): boolean {
		if (this.form.get(controlName)) {
			return (
				this.form.get(controlName)!.invalid &&
				(this.form.get(controlName)!.dirty || this.form.get(controlName)!.touched)
			);
		}
		return false;
	}
}
