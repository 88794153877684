import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { CompanyProfileRelationship, DisplayConfig, Layout } from "../../../../../Models";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { filter, map, takeUntil } from "rxjs";
import copy from "fast-copy";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { CurrencyCode, GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { RouteStepDataService } from "../../../Services";
import { TextStyle } from "src/app/Models/Enums";
import { SharedText } from "../shared-text";

@Component({
	selector: "ls-customer-revenue",
	templateUrl: "./customer-revenue.component.html",
	styleUrls: ["./customer-revenue.component.scss"]
})
export class CustomerRevenueComponent extends CustomerRelationshipBaseComponent {
	constructor(
		public actions$: Actions,
		public store: Store<any>,
		public router: Router,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public genericSelectors: GenericSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.setDefaultLayout(this.DefaultLayout);
		this.initData();
	}
	CURRENCY_CODE_TOKEN = "!CURRENCY_CODE!";
	ONBOARD_REVENUE_RANGE = "onboardRevenueRangeControl";
	customerRevenueForm: FormGroup = new FormGroup({});
	companyProfileRelationship?: CompanyProfileRelationship;
	public onboardRevenueRanges: GenericLookup<string>[] = [];
	currencyCode = CurrencyCode.USD;
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;
	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					this.currencyCode = cpr!.isOtherCurrency ? CurrencyCode.USD : cpr!.currencyCode!;
					this.replacementTokens.set(this.CURRENCY_CODE_TOKEN, this.currencyCode);
				})
			)
			.subscribe();

		this.store
			.select(this.genericSelectors.selectLookup(LookupTables.OnboardRevenueRange))
			.pipe(
				filter((rr) => !!rr),
				takeUntil(this.componentTeardown$),
				map((rr) => {
					this.onboardRevenueRanges = rr!;
				})
			)
			.subscribe();

		this.customerRevenueForm = new FormGroup({
			onboardRevenueRangeControl: new FormControl(
				this.companyProfileRelationship?.onboardRevenueRangeId,
				Validators.required
			)
		});
	}

	isFormInvalid() {
		return this.customerRevenueForm.invalid;
	}

	continue() {
		if (this.customerRevenueForm.dirty) {
			const selectedAnnualSales = this.customerRevenueForm.get(this.ONBOARD_REVENUE_RANGE)?.value;
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.onboardRevenueRangeId = selectedAnnualSales;
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hs1", new DisplayConfig(SharedText.CustomerSetup.tellUsMore, TextStyle.HEADLINE_SMALL)]
				])
			)
		],
		[
			this.ONBOARD_REVENUE_RANGE,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"q1-p1",
						new DisplayConfig(
							`What are your approximate annual sales with this customer (${this.CURRENCY_CODE_TOKEN})?`,
							TextStyle.TITLE_MEDIUM
						)
					]
				])
			)
		]
	]);
}
