<ls-questionnaire #questionnaireForm (nextButtonClicked)="continue()" [isNextButtonDisabled]="isFormInvalid()"
    [breadcrumbs]="breadcrumbs">
    <ls-text id="p1" extraClasses="ls-mb-8" [displayConfig]="config(HEADER,'hs1')"></ls-text>

    <form [formGroup]="customerRevenueForm" class="d-flex flex-column">
        <div *ngIf="display(ONBOARD_REVENUE_RANGE)" class="d-flex flex-column ls-mb-8">
            <ls-text id="q1-p1" extraClasses="ls-mb-3"
                [displayConfig]="config(ONBOARD_REVENUE_RANGE,'q1-p1')"></ls-text>
            <mdb-form-control class="half-max-width">
                <mdb-select [formControlName]="ONBOARD_REVENUE_RANGE" [id]="ONBOARD_REVENUE_RANGE" mdbValidate
                    [optionHeight]="56" class="form-control form-control-lg">
                    <mdb-option *ngFor="let onboardRevenueRange of onboardRevenueRanges"
                        [value]="onboardRevenueRange.id">{{ onboardRevenueRange.name }}</mdb-option>
                </mdb-select>
                <label mdbLabel class="form-label" [for]="ONBOARD_REVENUE_RANGE">Amount</label>
            </mdb-form-control>
        </div>
    </form>
</ls-questionnaire>