<div class="ls-mt-8" [formGroup]="customerSettingsForm" [class.grid]="!readonly">
	<ng-container *ngIf="!readonly; else readonlyView">
		<div class="g-col-12 g-col-md-6">
			<div class="g-col-12 ls-mb-8">
				<span>
					<label class="s">Customer Notification
						<ls-info-dialog-icon [header]="SharedText.CustomerSettings.notifyHeader"
							[content]="SharedText.CustomerSettings.notifyContent"></ls-info-dialog-icon>
					</label>


				</span>
				<mdb-form-control class="ls-mt-3">
					<mdb-select [formControlName]="CUSTOMER_NOTIFICATION" [id]="CUSTOMER_NOTIFICATION"
						[optionHeight]="56" mdbValidate class="form-control form-control-lg">
						<mdb-option *ngFor="let fd of financeDisclosures" [value]="fd.id">{{fd.name}}</mdb-option>
					</mdb-select>
					<label mdbLabel class="form-label" [for]="CUSTOMER_NOTIFICATION">Customer Notification</label>
				</mdb-form-control>
			</div>
			<div class="g-col-12 ls-mb-8">
				<div class="d-flex flex-column">
					<span class="ls-mb-3">
						<label class="s">autoXcel<ls-info-dialog-icon [header]="SharedText.AutoXcel.tooltipHeader"
								[content]="SharedText.AutoXcel.tooltipContent"></ls-info-dialog-icon></label>

					</span>
					<div class="d-flex flex-row align-items-center">
						<ls-toggle [controlName]="AUTOXCEL"
							[toggled]="companyProfileRelationship!.isAutoXcel!"></ls-toggle>
						<label class="xs-bold ls-ml-3">{{customerSettingsForm.get(AUTOXCEL)?.value! | booleanDisplay :
							'EnabledDisabled'}}</label>
					</div>
				</div>
			</div>
			<div class="g-col-12 ls-mb-5">
				<span>
					<label class="s">Preferred Payment Method<ls-info-dialog-icon
							(iconClick)="showPaymentInfo()"></ls-info-dialog-icon></label>

				</span>
				<mdb-form-control class="ls-mt-3">
					<mdb-select [formControlName]="PAYMENT_METHOD" [id]="PAYMENT_METHOD" [optionHeight]="56" mdbValidate
						class="form-control form-control-lg">
						<mdb-option *ngFor="let payment of preferredPayments"
							[value]="payment.id">{{payment.name}}</mdb-option>
					</mdb-select>
					<label mdbLabel class="form-label" [for]="PAYMENT_METHOD">Preferred Payment Method</label>
				</mdb-form-control>
			</div>
		</div>
		<div class="g-col-12 g-col-md-6 ls-pl-md-17">
			<div class="g-col-12 ls-mb-8">
				<div class="d-flex flex-column">
					<span class="ls-mb-3">
						<label class="s">Invoice Approvals
							<ls-info-dialog-icon [header]="SharedText.InvoiceApprovals.tooltipHeader"
								[content]="SharedText.InvoiceApprovals.tooltipContent"></ls-info-dialog-icon>
						</label>

					</span>
					<div class="d-flex flex-row align-items-center">
						<ls-toggle [controlName]="INSTRUMENT_APPROVAL_CONFIRMED"
							[toggled]="companyProfileRelationship!.isInstrumentApprovalConfirmed!"></ls-toggle>
						<label class="xs-bold ls-ml-3">{{customerSettingsForm.get(INSTRUMENT_APPROVAL_CONFIRMED)?.value!
							| booleanDisplay : 'YesNo'}}</label>
					</div>
				</div>
			</div>
			<div class="g-col-12">
				<div class="d-flex flex-column">
					<span class="ls-mb-3">
						<label class="s">Expedited Financing
							<ls-info-dialog-icon [header]="SharedText.ExpeditedFinancing.tooltipHeader"
								[content]="SharedText.ExpeditedFinancing.tooltipContent"></ls-info-dialog-icon>
						</label>

					</span>
					<div class="d-flex flex-row align-items-center">
						<ls-toggle [controlName]="FINANCING_EXPEDITED"
							[toggled]="companyProfileRelationship!.isFinanceExpedited!"></ls-toggle>
						<label class="xs-bold ls-ml-3">{{customerSettingsForm.get(FINANCING_EXPEDITED)?.value! |
							booleanDisplay : 'YesNo'}}</label>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>

<ng-template #readonlyView>
	<ls-customer-settings-readonly
		[companyProfileRelationship]="companyProfileRelationship"></ls-customer-settings-readonly>
</ng-template>