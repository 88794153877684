import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, filter, map, Observable, take } from "rxjs";
import { AppSelectors, CompanyProfileActions, CompanyProfileSelectors } from "../../../AppStateManagement";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CompanyProfile } from "../../../Models";

@Injectable({ providedIn: "root" })
export class CompanyResolverService {
	constructor(
		private store: Store<any>,
		private companyProfileSelectors: CompanyProfileSelectors,
		private appSelectors: AppSelectors
	) {}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CompanyProfile | undefined> {
		this.initPrefetchData();
		return this.companyProfile$;
	}
	private companyProfile$ = this.store.select(this.companyProfileSelectors.selectCompanyProfile).pipe(
		filter((c) => !!c),
		take(1)
	);

	private initPrefetchData(): void {
		combineLatest([
			this.store.select(this.appSelectors.selectUserRoles),
			this.store.select(this.companyProfileSelectors.selectCompanyProfile)
		])
			.pipe(
				filter(([userRole, cp]) => !!userRole && userRole.roles.length > 0 && !cp),
				take(1),
				map(([userRole]) => {
					this.store.dispatch(CompanyProfileActions.getCompanyProfile({ companyId: userRole!.roles[0].companyId }));
				})
			)
			.subscribe();
	}
}
