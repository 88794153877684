import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { RouteStepDataBaseComponent } from "../../../../Core/route-step-data-base.component";
import { RouteStepDataService } from "../../Services";
import { DisplayConfig, Layout } from "../../../../Models";
import { DocusignDocument, TextStyle } from "../../../../Models/Enums";
import { Store } from "@ngrx/store";
import { DocusignActions } from "../../OnboardingStateManagement/Docusign/docusign-actions";

@Component({
	selector: "ls-purchase-agreement",
	templateUrl: "./purchase-agreement.component.html",
	styleUrls: ["./purchase-agreement.component.scss"]
})
export class PurchaseAgreementComponent extends RouteStepDataBaseComponent {
	constructor(
		public router: Router,
		public routeStepDataService: RouteStepDataService,
		private store: Store
	) {
		super(routeStepDataService);
		this.setDefaultLayout(this.DefaultLayout);
	}

	onClick() {
		this.store.dispatch(
			DocusignActions.getDocumentUrl({ document: DocusignDocument.RPA, redirectUrl: this.activeRouteData!.nextUrl![0] })
		);
	}

	DefaultLayout = new Map([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig("Almost there!", TextStyle.HEADLINE_LARGE)],
					[
						"p1",
						new DisplayConfig(
							"All that’s left to do is sign the Receivables Purchase Agreement (RPA) by simply clicking the button below.",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		],
		[
			this.BUTTONS,
			new Layout(new Map<string, DisplayConfig>([["b1", new DisplayConfig("Sign agreement", TextStyle.BUTTON_LARGE)]]))
		],
		[
			this.FOOTER,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"p1",
						new DisplayConfig(
							"After you sign the RPA, a Raistone representative may reach out to you with any potential follow-up questions to complete your onboarding.",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		]
	]);
}
