<h2 class="no-text-transform ls-mb-3">Would you collaborate with us to resolve any conflicting liens filed against your
  company?</h2>
<div class="ls-mb-3">
  <span class="l">We’re going to search for any liens filed against assets of your
    <span class="text-nowrap">company.
      <ls-info-dialog-icon [header]="SharedText.LienCollaboration.tooltipHeader"
        [content]="SharedText.LienCollaboration.tooltipContent"></ls-info-dialog-icon>
    </span>
  </span>
</div>
<ls-accordion header="Examples of liens" [expanded]="false">
  <ul class="no-indent-list">
    <li>
      <p class="l ls-mb-2">
        Equipment or inventory liens
      </p>
    </li>
    <li>
      <p class="l ls-mb-2">
        Loans or line of credit liens
      </p>
    </li>
    <li>
      <p class="l">
        Property liens
      </p>
    </li>
  </ul>
</ls-accordion>
<form class="d-flex flex-column ls-mt-3 ls-mb-9" [formGroup]="form">
  <p class="l ls-mb-3">If you have any liens, would you collaborate with us to resolve any conflicting liens?</p>
  <p class="l ls-mb-8">You can get more favorable pricing if there’s no other conflicting owners of the receivables
    we’re looking to acquire.</p>
  <div class="ls-mb-3 d-flex align-items-center" *ngFor="let yn of yesNo">
    <input mdbRadio [formControlName]="YESNO" class="radio haveLien-input" type="radio" [name]="YESNO" id="{{yn.id}}"
      value="{{yn.id}}" />
    <label class="xl" for="{{yn.id}}">{{ yn.name }}</label>
  </div>
</form>
<button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg" [disabled]="form.invalid" #lsNextBtn>
  Next <span class="material-icons md-18"> arrow_forward</span>
</button>