import { Component, OnDestroy } from "@angular/core";
import {
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
	Validators
} from "@angular/forms";
import { map, Subject, takeUntil } from "rxjs";

@Component({
	selector: "ls-payment-terms-form-control",
	templateUrl: "./payment-terms-form-control.component.html",
	styleUrls: ["./payment-terms-form-control.component.scss"],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: PaymentTermsFormControlComponent, multi: true },
		{ provide: NG_VALIDATORS, useExisting: PaymentTermsFormControlComponent, multi: true }
	]
})
export class PaymentTermsFormControlComponent implements OnDestroy, ControlValueAccessor, Validator {
	protected componentTeardown$ = new Subject();

	PAYMENT_TERMS = "paymentTerms";

	formGroup: FormGroup = new FormGroup({});
	max = 365;
	constructor() {
		this.formGroup.addControl(
			this.PAYMENT_TERMS,
			new FormControl(null, [
				Validators.required,
				Validators.min(0),
				Validators.max(this.max),
				Validators.pattern("^[0-9]+$")
			]),
			{ emitEvent: false }
		);
	}

	ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}
	isPaymentTermsNotNullOrUndefined() {
		return this.formGroup.get(this.PAYMENT_TERMS)?.value != null;
	}

	controlHasError(controlName: string): boolean {
		if (this.formGroup.get(controlName)) {
			return (
				this.formGroup.get(controlName)!.invalid &&
				(this.formGroup.get(controlName)!.dirty || this.formGroup.get(controlName)!.touched)
			);
		}
		return false;
	}

	getControlError(controlName: string): string {
		const error: string[] = [];
		if (this.formGroup.get(controlName)?.errors!["max"]) {
			error.push("Please limit payment terms to 365 days.");
		}
		if (this.formGroup.get(controlName)?.errors!["pattern"]) {
			error.push("Please use whole numbers only.");
		}
		return error.join(" ");
	}
	setDisabledState(isDisabled: boolean) {
		isDisabled ? this.formGroup.disable() : this.formGroup.enable();
	}

	registerOnChange(onChange: (value: number | null) => number): void {
		this.formGroup.valueChanges
			.pipe(
				takeUntil(this.componentTeardown$),
				map((value) => onChange(value[this.PAYMENT_TERMS]))
			)
			.subscribe();
	}
	onTouched() {}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	validate(): ValidationErrors | null {
		if (this.formGroup.valid) {
			return null;
		}
		return this.formGroup.controls[this.PAYMENT_TERMS].errors;
	}
	registerOnValidatorChange?(fn: () => void): void {
		this.validatorChange = fn;
	}
	validatorChange() {}
	writeValue(paymentTerms: number): void {
		this.formGroup.get(this.PAYMENT_TERMS)?.setValue(paymentTerms ?? null, { emitEvent: false });
	}
}
